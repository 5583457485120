<template>
    <div class="home">
    <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
            <span style="color:#777; font-size:16px;padding:5px;">Department / Assign course to lecturer </span>
        </div>
    </div>
    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
        <div style="margin:auto; padding:20px; margin-top:100px" class="col-md-5" v-if="showTable">
                <v-card elevation="3" height="300">
                    <v-card-title style="color:#666; font-size:18px">
                        Select Department
                    </v-card-title>
                    <v-divider style="margin-top:0px"></v-divider>

                    <v-col cols="11" style="margin:auto;padding-top:45px" pa-4>
                        <!-- {{departmentSearch}} -->
                        <v-autocomplete v-model="depSearch" :items="departmentSearch" style="max-width: 100% !important" outlined label="Select Department" dense></v-autocomplete>

                        <!-- <v-text-field outlined label="Enter staff CIRMS ID / staff ID" v-model="staffId" placeholder="CIRMS ID / Staff ID"></v-text-field> -->
                        <v-card-actions>
                        <v-btn color="#c0e7fe" @click="listCourses" :disabled="staffId==''" v-if="!activity" class="col-md-7 ma-auto" >Next</v-btn>
                        <v-btn color="primary"  elevation='2' class="col-md-7 ma-auto"  style="background-color:#e0e0e0 !important;color:#444  !important;padding:10px;cursor: pointer !important; " disabled v-else> <v-progress-circular
      indeterminate
      color="#777" :size="25"
    ></v-progress-circular> &nbsp; Processing</v-btn>
                        </v-card-actions>
                    </v-col>
                </v-card>
            </div> 


            <div  v-if="!showTable && facDep.length <= 0" class="col-md-12 ">
                <v-card>
                    <v-card-title >
                   <h6 style="margin-top:10px"> Courses Hosted by {{ depSearch.name }} Department</h6>
                </v-card-title>
                </v-card>
                <div class="row">
                <!-- <div class="col-md-4">
                    <span class="pa-2" @click="moveBack" style="cursor: pointer">
                <v-icon large>mdi-arrow-left-circle</v-icon>
                Back
            </span>
                    </div> -->
                
            <!-- <div class="col-md-8" style="font-size:18px; padding-left: 20px;">Hosting Courses for {{depSearch.value.name}}</div> -->
            <div class="col-md-4">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Hosted Courses" single-line hide-details style="margin-bottom:15px"></v-text-field>
            </div>
            <v-spacer></v-spacer>
            <div class="col-md-4" style="text-align:right">
                      
                    </div>
        </div>

        <v-data-table :headers="headersFirst" :items="desserts" :sort-desc="[false, true]" multi-sort :search="search" class="elevation-1">
                       
                        <template v-slot:item.sn="{ index}">
                            {{index + 1}}
                        </template>
                        <template v-slot:item.actions="{ item }">
                            
                            <v-tooltip top color="default">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon small class="mr-2" @click="pushtoAsign(item)" v-bind="attrs">
                                        mdi-cog
                                    </v-icon>
                                </template>
                                <span>Assign Course To Lecturer</span>
                            </v-tooltip>
                          

                        </template>
                    </v-data-table>
                    </div>

















        <v-row style="margin:0 !important; padding:0 !important">
    <v-col cols="12" style="">
        <div class="row">

            </div></v-col></v-row>
        </div>
  <div class="mt-10 container-fluid" >
      
      <div class="row">
          <div class="col-lg-12">
                
              <v-card  v-if="facDep.length > 0" elevation="0" class="col-md-12" >
                <span
          class="mb-5 d-block"
          @click="goback()"
          style="cursor: pointer"
        >
          <v-icon>mdi-arrow-left-circle</v-icon>
          Back
        </span>
                <v-card-title style="color:#666; font-size:18px">
                        Faculties and Departments that profiled {{ course.ctitle }} ({{ course.ccode }})
                    </v-card-title>
                    <v-divider style="margin-top:0px"></v-divider>
                  <template>
                      <v-data-table v-for="(fac, i) in facDep" :key="i" :headers="headers" :items="fac.departments" sort-by="calories" class="elevation-1 mb-8">
                          <template v-slot:top>
                              <v-toolbar flat>
                                  <div class="facultyName">{{fac.faculty.name}}</div>
                                  <v-spacer></v-spacer>
                                  <!-- <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field> -->
                                  <!-- <button class="assignBtn" @click="assignCourse">
                                      Assign Lecturer
                                  </button> -->
  
                              </v-toolbar>
                          </template>
                          <template v-slot:item.sn="{ item }">
                              {{fac.departments.indexOf(item) + 1}}
                          </template>
                          <template v-slot:item.lecturer="{ item }">
                              <span v-if="item.lecturer.length == 0">No Lecturer is assigned to this department yet</span>
                              <span v-else>
                                  <span v-for="(lec, i) in item.lecturer" :key="i">
                                      <span class="pa-1"></span>{{lec}}
                                  </span>
                              </span>
                          </template>
                          <template v-slot:item.actions="{ item }">
                              <v-btn @click="assignCourse(item)" style="color: green; text-transform: capitalize" text>Assign</v-btn>
                          </template>
                      </v-data-table>
                  </template>
              </v-card>
            
              <v-dialog v-model="assignModal"  max-width="500">
                  <v-card style="background: #FFFFFF; border-radius: 6px;" max-height="350">
                      <span @click="ClassignModal" style="float:right; cursor: pointer; color: #21618C; margin: 20px;"><i class="fas fa-times"></i></span>
                      <div v-if="warn">
                          <div class="textDiv pa-8">
                              <!-- <img src="../../../assets/complain 1.svg" alt="" srcset=""> -->
                              <div>
                                  You are about to assign {{ course.ccode}} ({{ course.ctitle  }}) to a lecturer for <spsn v-if="item">{{ item.academicyear +  parseInt(item.academicyear)+ 1}} Academic session</spsn>, click
                                  Next to Continue or
                                  cancel to Exit.
                                </div>
                          </div>
                          <div class="btnc ">
                              <button class="cancelBtn" @click="assignModal = false">Cancel</button>
                              <button class="nextBtn" @click="nextStep()">Next</button>
                          </div>
                      </div>
                      <div class="selectFields pa-8" v-else>
                          <h4 class="textWarn">
                              Please Select the Lecturer.
                          </h4>
                          <v-form>
                              <v-container>
                                  <v-row>
                                      <v-col cols="12">
                                          <v-autocomplete v-model="selectedLecturers" :items="people" filled chips color="blue-grey lighten-2" label="Lecturers" item-text="name" item-value="name" multiple>
  
                                              <template v-slot:selection="data">
                                                  <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)">
                                                      <v-avatar left>
                                                          <v-img :src="data.item.avatar"></v-img>
                                                      </v-avatar>
                                                      {{ data.item.name }}
                                                  </v-chip>
                                              </template>
                                              <template v-slot:item="data">
                                                  <template v-if=" data.item !== 'object'">
                                                      <v-list-item-content v-text="data.item.name">
                                                      </v-list-item-content>
                                                  </template>
                                                  <template v-else>
                                                      <v-list-item-avatar>
                                                          <img :src="data.item.avatar">
                                                      </v-list-item-avatar>
                                                      <v-list-item-content>
                                                          <v-list-item-title v-html="data.item.name">
                                                          </v-list-item-title>
                                                          <v-list-item-subtitle v-html="data.item.group">
                                                          </v-list-item-subtitle>
                                                      </v-list-item-content>
                                                  </template>
                                              </template>
                                          </v-autocomplete>
  
                                      </v-col>
                                  </v-row>
                              </v-container>
                          </v-form>
                          <button class="submitBtn" @click="submit(item)">Submit</button>
                      </div>
                  </v-card>
              </v-dialog>
          </div>
      </div>
  </div>
  </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Swal from 'sweetalert2'
  import { degree, allSession, allDepartments,menusAsigned,fetchactiveSets } from "@Appmart/utility";

  
  const srcs = {
      1: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
      2: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
      3: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
      4: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
      5: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
  }
  export default {
      data() {
  
          return {
////// newly added 
showTable:true,
departmentSearch:[],
depSearch:'',
program:this.$activeSetting.data.program._id,
              noLect: 'No Lecturer',
              assignModal: false,
              course: {},
              dialogDelete: false,
              showInput: false,
              departmentsName: '',
              warn: true,
              showList: false,
              search: '',
              faculty: [],
              Newdepart: [],
              selectedLecturers: [],
              friendsList: [],
              departmentCode: '',
              autoUpdate: true,
              friendsSecond: ['Sandra Adams', 'Britta Holt'],
              isUpdating: false,
              name: 'Midnight Crew',
              people: [],
              title: 'The summer breeze',
              headersFirst: [{
                    text: 'S/N',
                    value: 'sn'
                },

                {
                    text: 'Course Title',
                    align: 'start',
                    sortable: false,
                    value: 'ctitle',
                },
                {
                    text: 'Course Code',
                    value: 'ccode'
                },
                {
                    text: 'Course Description',
                    value: 'cdes'
                },
                {
                    text: 'Actions',
                    value: 'actions'
                },

            ],
              headers: [{
                      text: '#',
                      value: 'sn',
                      class: 'blue-grey lighten-5'
                  },
                  {
                      text: 'Department',
                      align: 'start',
                      sortable: false,
                      value: 'department',
                      class: 'blue-grey lighten-5'
                  },
                  {
                      text: 'Level',
                      value: 'level',
                      class: 'blue-grey lighten-5'
                  },
                  {
                      text: 'Lecturer',
                      value: 'lecturer',
                      class: 'blue-grey lighten-5'
                  },
                  {
                      text: 'Academic Year',
                      value: 'academicyear',
                      class: 'blue-grey lighten-5'
                  },
                  {
                      text: 'Assign',
                      value: 'actions',
                      class: 'blue-grey lighten-5'
                  },
              ],
              facDep: [],
              staffArr: [],
              editedIndex: -1,
              editedItem: {
                  sn: '',
                  department: 'Lab Tech',
                  level: 100,
                  academicyear: 2022,
                  lecturer: [],
              },
              defaultItem: {
                  sn: '',
                  department: 'Lab Tech',
                  level: 100,
                  academicyear: 2022,
                  lecturer: [],
              },
              item: null,
          }
      },
  
     
  
      mounted() {
    this.mydepartments();
    this.allowedMenu = this.$router.currentRoute.params.getmyMenus;
      },
  
      watch: {
          dialog(val) {
              val || this.close()
          },
          dialogDelete(val) {
              val || this.closeDelete()
          },
      },
  
      methods: {

        ///// set the back button to select anothr course
        goback(){

            this.showTable=false  
            this.facDep=[]
        },


        async listCourses() {
            this.activity = true
            console.log('sdsd',this.depSearch);
            try {
                if (!this.depSearch || !this.program){
this.activity = false
                return Swal.fire({
                    icon: 'info',
                    text: 'Please select Program and Department',
                    title: 'Oops..'
                });
            }
                var url = `${this.$appUrl}/get-courses?programId=${this.program}&offeringDepartment=${this.depSearch.id}`
                const res = await axios.get(url);
                console.log('HERE', res);
                if (res.data.code == 's200') {

                    this.showTable = false;
                    this.desserts = [];
                    res.data.message.forEach((course) => {
                        this.desserts.push({
                            ctitle: course.name,
                            ccode: course.course_code,
                            cdes: course.description,
                            department: course.offeringDepartment,
                            id: course._id
                        })
                    })
                } else {
                    this.showTable = false;
                    Swal.fire({
                        icon: 'info',
                        text: res.data.message,
                        title: 'Oops..'
                    })
                }
                this.activity = false

            } catch (error) {
                this.activity = false
                console.log(error);
            }
        },
        
        pushtoAsign(item) {
            
              const data = {
                  item,
                  department: this.depSearch,
                  program: this.$activeSetting.data.program._id
              }
              this.fetchDepartmentFacultyThatProfiledCourse(data)
              this.fetchStaff();

              console.log(data);
              // localStorage.setItem('courrrrrrse', JSON.stringify(data))
            //   const hashed = btoa(JSON.stringify(data));
            //   this.$router.push(`assign-course/${hashed}`)
           
          },

          editItem(item) {
              this.editedIndex = this.desserts.indexOf(item)
              this.editedItem = Object.assign({}, item)
              this.dialog = true
          },
  
          ClassignModal() {
              // this.showInput = false,
              this.assignModal = false
              this.friends = ['Sandra Adams', 'Britta Holt']
              // setTimeout(() => {
              //     this.warn = true
              // }, 1000);
          },
  
          remove(item) {
              const index = this.friends.indexOf(item.name)
              if (index >= 0) this.friends.splice(index, 1)
          },
  
          deleteItem(item) {
              this.editedIndex = this.desserts.indexOf(item)
              this.editedItem = Object.assign({}, item)
              this.dialogDelete = true
          },
  
          deleteItemConfirm() {
              this.desserts.splice(this.editedIndex, 1)
              this.closeDelete()
          },
  
          close() {
              this.dialog = false
              this.$nextTick(() => {
                  this.editedItem = Object.assign({}, this.defaultItem)
                  this.editedIndex = -1
              })
          },
  
          assignCourse(item) {
              this.selectedLecturers = item.lecturer;
              this.item = item;
              this.Newdepart.push(item)
               this.showInput = false;
                      this.warn = true;
              this.assignModal = true;
                  this.departmentsName = item.department;
          },
  
          closeDelete() {
              this.dialogDelete = false
              this.$nextTick(() => {
                  this.editedItem = Object.assign({}, this.defaultItem)
                  this.editedIndex = -1
              })
          },
  
          save() {
              if (this.editedIndex > -1) {
                  Object.assign(this.desserts[this.editedIndex], this.editedItem)
              } else {
                  this.desserts.push(this.editedItem)
              }
              this.close()
          },
  
          nextStep() {
              this.showInput = true
              this.warn = false
          },
  
          async fetchStaff() {
              try {
                  // const data = {
                  //     depunit_code: this.departmentCode,
                  //     teaching_status: 'Teaching'
                  // }
  var url = this.$appUrl+`/listadmin?depunit_code=${this.departmentCode}&teaching_status=${'Teaching'}`
                  const res = await axios.get(url);
                  console.log('Department',res);
                  if (res.data.code === '00') {
                      res.data.message.forEach(staff => {
                          this.staffArr.push({
                              name: `${staff.name} ${staff.othernames} ${staff.surname}`,
                              value: staff._id,
                              avatar: srcs[1]
                          })
  
                          this.people.push({
                              name: `${staff.name} ${staff.othernames} ${staff.surname}`,
                              value: staff._id,
                              avatar: srcs[1]
                          })
                      })
                  }
              } catch (error) {
                  console.log(error);
              }
  
          },

          async mydepartments() {
        try {

                    const alldepartments = await allDepartments()
          .then((departments) => {
            return departments;
          })
          .catch((e) => {
            console.log(e);
          });


        ///// fiter the departments that are needed.

        alldepartments.data.message.forEach(dep => {
        this.allowedMenu.department.some((f) => {
          if(f == dep._id){
        this.departmentSearch.push({
                            text: dep.name,
                            value: {
                                id: dep._id,
                                name: dep.name,
                                code: dep.code
                            }
                        });
                      }
                    })
       });



      }catch(e){
console.log(e);
      }

    },

  
          async fetchDepartmentFacultyThatProfiledCourse(data) {
            console.log({data});
              try {
                  this.course = data.item;
                  this.departmentCode = data.department.code;
                  var url = this.$appUrl+`/get-department-profiled-course`
                  const res = await axios.post(url, {
                      course: data.item.id,
                      program: data.program
                  });
  
                  console.log('Response', res);
                  
                  if(res.data.code == 's200'){
                      if(res.data.message.length > 0){
  
                      this.facDep = res.data.message.map(fac => {
                          return {
                              faculty: fac.faculty,
                              departments: fac.course.map(dep => {
                                  return {
                                      department: dep.department.name,
                                      departmentId: dep.department._id,
                                      courseId: dep.course._id,
                                      level: dep.level + '00',
                                      facultyId: dep.faculty._id,
                                      academicyear: fac.session,
                                      lecturer: [],
                                  }
                              })
                          }
                      });
                      this.fetchAssigned();
                    }

                      if(this.facDep.length ==0){
                        Swal.fire({
                        icon: 'error',
                        text: `No Department has profiled ${data.item.ctitle} (${data.item.ccode}) this session.`,
                        title: 'Assing Lecturer'
                    })
                      }
  
                  }
  
                  // console.log(res);
              } catch (error) {
                  console.log(error);
              }
          },
  
          async submit() {
              try {
                  console.log(this.selectedLecturers, this.staffArr);
                  const selectedLecturers = [...new Set(this.selectedLecturers)]
                  const lecturers = [];
  
                  selectedLecturers.map(lecturer => {
                      console.log('ledccc');
                      this.staffArr.forEach(el =>{
                          if(lecturer == el.name){
                              lecturers.push(el.value);
                          }
                      } )
                  })
  
                  console.log('here',this.lecturers);
  
                  
  
                  let data = {
                      lecturers,
                      level: this.item.level[0],
                      session: this.item.academicyear,
                      courseId: this.item.courseId,
                      department: this.item.departmentId,
                      facultyId: this.item.facultyId
                  };
  
                  // console.log('Paylaod',data);
                  var url = this.$appUrl+`/assign-course-to-lecturer`
                  const res = await axios.post(url, data);
                  console.log(res);
                  if(res.data.code == 's200'){
  
                      //update department the course was assigned to
                      //1) find faculty
                      this.facDep[this.facDep.indexOf(this.facDep.find(fac => fac.faculty._id === this.item.facultyId))].departments.forEach(dep => {
                          if(dep.departmentId === this.item.departmentId){
                              dep.lecturer.push(...this.selectedLecturers);
                              console.log(dep);
                          }
                      })
  
  
                      this.showInput = false;
                      this.warn = true;
                      this.assignModal = false;
                      this.selectedLecturers = [];
                      Swal.fire({icon: 'success', text: 'Course successfully assigned to lecturer', title: 'Successful'})
                  }
              } catch (error) {
                  console.log(error);
              }
          },
          buildFacultyDepartmetMenu() {
              // console.log('FAculty', this.faculties);
              // console.log('Department', this.departments)
              const mapData = this.faculties.map(faculty => {
                  return {
                      id: faculty._id,
                      name: faculty.name,
                      departments: this.departments.filter(department => {
                          if (department.faculty._id === faculty._id) {
                              department['lecturer'] = [];
                              department['level'] = '';
                              return department;
                          }
                      })
                  }
              });
              this.facDep.push(...mapData);
              // console.log('HERE THEN',this.facDep);
          },
        //   async fetchDepartments() {
        //       try {
        //           const res = await axios.get("http://127.0.0.1:5000/getalldepartments");
  
        //           if (res.data.code === '00') {
        //               this.departments.push(...res.data.data);
        //               this.buildFacultyDepartmetMenu();
        //           }
        //       } catch (error) {
        //           console.log(error);
        //       }
        //   },
        //   async fetchFaculties() {
        //       try {
        //           const res = await axios.get('http://127.0.0.1:5000/fetchallfaculty');
        //           if (res.data.code == '00') {
        //               this.faculties.push(...res.data.data);
        //           }
  
        //       } catch (error) {
        //           console.log(error);
        //       }
        //   },
  
          async fetchAssigned() {
              try {
                var url = this.$appUrl+'/fetch-assigned-courses'
                  const res = await axios.post(url, {
                      courseId: this.course.id
                  });
                  console.log('Assigned Courses', res);
                  if (res.data.code === 's200') {
                      res.data.message.forEach(assignedCourse => {
                          const facultyIndex = this.facDep.findIndex(fac => fac.faculty._id === assignedCourse.facultyId);
                          if (facultyIndex + 1) {
                              this.facDep[facultyIndex].departments.forEach(dep => {
                                  console.log(dep, assignedCourse)
                                  if (dep.departmentId === assignedCourse.department._id && (assignedCourse.level + '00') == dep.level) {
                                      const index = this.facDep[facultyIndex].departments.indexOf(dep);
                                      this.facDep[facultyIndex].departments[index].lecturer = assignedCourse.lecturers.map(lec => `${lec.surname} ${lec.name} ${lec.othernames}`);
                                      // this.facDep[facultyIndex].departments[index].academicyear = assignedCourse.session.slug,
                                      //     this.facDep[facultyIndex].departments[index].level = assignedCourse.level;
                                      // console.log(dep)
                                  }
                              })
                          }
                      });
                  }
              } catch (error) {
                  console.log(error);
              }
          },
  
          // submit(item) {
          //     item = this.Newdepart
          //     item[0].lecturer = []
          //     item[0].lecturer.push(this.friends)
          //     console.log({ item: item[0].lecturer })
          //     this.warn = true,
          //     this.showInput = false
          //     this.editedItem.lecturer = []
          //     this.editedItem.lecturer.push(this.friends)
          //     console.log(this.editedItem.lecturer)
          //     if (this.editedIndex > -1) {
          //         Object.assign(this.departments[this.editedIndex], this.editedItem)
          //     } else {
          //     this.departments.push(this.editedItem)
          //     // }
          //     Swal.fire({
          //         icon: 'success',
          //         width: 350,
          //         text: 'Lecturer Added',
          //     })
          //     console.log(this.friendsSecond)
          //     console.log(this.friends);
          //     this.assignModal = false
          //     console.log(this.friends);
          // }
      },
  }
  </script>
  
  <style lang="scss" scoped>
  .textDiv {
      display: flex;
      flex-direction: column;
      align-items: center;
  }
  
  .selectFields {
      width: 100%;
  }
  
  .assignBtn {
      width: 10%;
      height: 49px;
      background: #21618C;
      border-radius: 3px;
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      margin: 40px;
      color: #FFFFFF;
  }
  
  .facultyName {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      color: #4F4F4F;
  }
  
  .submitBtn {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px;
      width: 230px;
      height: 59px;
      background: #21618C;
      border-radius: 3px;
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
      margin: 10px;
      color: #FFFFFF;
  }
  
  .btnc {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
  
      .nextBtn {
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px;
          width: 230px;
          height: 59px;
          background: #21618C;
          border-radius: 3px;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 20px;
          text-align: center;
          margin: 10px;
          color: #FFFFFF;
      }
  
      .cancelBtn {
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px;
          width: 230px;
          height: 59px;
          background: #DFDFDF;
          border-radius: 3px;
          margin: 10px;
          font-family: 'Proxima Nova';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 20px;
          text-align: center;
  
          color: #828282;
      }
  }
  
  .textWarn {
      font-family: 'Proxima Nova';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      text-align: center;
      color: #4F4F4F;
  }
  </style>
  