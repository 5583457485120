<template>
    <div class="home">
    <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
            <span style="color:#777; font-size:16px;padding:5px;">Department / Hosted Courses </span><span style="color:#555" v-if="!showTable" >/ Hosting Courses for <strong>{{depSearch.value.name}}</strong></span>
        </div>
    </div>
    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">

        <div style="margin:auto; padding:20px; margin-top:100px" class="col-md-5" v-if="showTable">
                <v-card elevation="3" height="300">
                    <v-card-title style="color:#666; font-size:18px">
                        <!-- {{programSearch}} -->
                        Select Department
                    </v-card-title>
                    <v-divider style="margin-top:0px"></v-divider>

                    <v-col cols="11" style="margin:auto;padding-top:45px" pa-4>
                        <!-- {{departmentSearch}} -->
                        <v-combobox v-model="depSearch" :items="departmentSearch" style="max-width: 100% !important" outlined label="Select Department"></v-combobox>

                        <!-- <v-text-field outlined label="Enter staff CIRMS ID / staff ID" v-model="staffId" placeholder="CIRMS ID / Staff ID"></v-text-field> -->
                        <v-card-actions>
                        <v-btn color="#c0e7fe" @click="listCourses" :disabled="staffId==''" v-if="!activity" class="col-md-7 ma-auto" >Next</v-btn>
                        <v-btn color="primary"  elevation='2' class="col-md-7 ma-auto"  style="background-color:#e0e0e0 !important;color:#444  !important;padding:10px;cursor: pointer !important; " disabled v-else> <v-progress-circular
      indeterminate
      color="#777" :size="25"
    ></v-progress-circular> &nbsp; Processing</v-btn>
                        </v-card-actions>
                    </v-col>
                </v-card>
            </div> 
            <div v-else class="col-md-12 ">
                <div class="row">
                <div class="col-md-4">
                    <span class="pa-2" @click="moveBack" style="cursor: pointer">
                <v-icon large>mdi-arrow-left-circle</v-icon>
                Back
            </span>
                    </div>
                
            <!-- <div class="col-md-8" style="font-size:18px; padding-left: 20px;">Hosting Courses for {{depSearch.value.name}}</div> -->
            <div class="col-md-4">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Hosted Courses" single-line hide-details style="margin-bottom:15px"></v-text-field>
            </div>
            <v-spacer></v-spacer>
            <div class="col-md-4" style="text-align:right">
                         <span  @click="openActionModal" class="pa-5"  style="cursor: pointer">
                <v-icon large>mdi-plus-circle</v-icon>
                upload course(s)
            </span>
                    </div>
        </div>

        <v-data-table :headers="headers" :items="desserts" :sort-desc="[false, true]" multi-sort :search="search" class="elevation-1">
                        <template v-slot:item.actions="{ item }">
                            <v-tooltip top color="default">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon small class="mr-2" @click="showEdit(item)" v-bind="attrs">
                                        mdi-pencil
                                    </v-icon>
                                </template>
                                <span>Edit</span>

                            </v-tooltip>
                            <v-tooltip top color="default">
                                <!-- <template v-slot:activator="{ on, attrs }">
                                    <v-icon small class="mr-2" @click="pushtoAsign(item)" v-bind="attrs">
                                        mdi-cog
                                    </v-icon>
                                </template> -->
                                <span>Assign Course To Lecturer</span>
                            </v-tooltip>
                            <v-tooltip bottom color="default">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon small class="mr-2" @click="showDelete(item)" v-bind="attrs">
                                        mdi-delete
                                    </v-icon>
                                </template>
                                <span>Delete</span>
                            </v-tooltip>

                        </template>
                        <template v-slot:item.sn="{ index}">
                            {{index + 1}}
                        </template>
                    </v-data-table>
        <!-- <div class="btn-back">
            <span class="pa-2" @click="moveBack" style="cursor: pointer">
                <v-icon>mdi-arrow-left</v-icon>
                Back
            </span>
        </div> -->
        <div class="row">
            <div class="col-md-12">
                <div style="text-align:center">
                    <v-dialog persistent v-model="dialog" max-width="500px">

                        <v-card class="" max-width="">
                            <v-card-title style="color:#666; font-size:18px">
                                Add hosted course(s) for {{depSearch.value.name}}
                                <v-spacer></v-spacer>
                                <v-icon @click="close">
                                    mdi-close-circle
                                </v-icon>
                            </v-card-title>
                            <v-divider style="margin-top:-3px"></v-divider>
                           

                            <v-card-text>
                            <div class="mt-6">
                                <v-text-field outlined label="Course Title" v-model="editedItem.ctitle" placeholder="Course Title" dense></v-text-field>
                                <v-text-field outlined label="Course Code" v-model="editedItem.ccode" placeholder="Course Code" dense></v-text-field>
                                <v-textarea outlined label="Course Description" v-model="editedItem.cdes" placeholder="Course Description" no-resize dense :rows="4"></v-textarea>
                            </div>
                       
                            <!-- <div style="text-align: center; padding-top: 20px">
                                <b-form-select v-model="program" size="lg" :options="programArr" style="width: 100%;"></b-form-select>
                            </div>
                            <div style="text-align: center; padding-top: 20px">
                                <b-form-select v-model="department" size="lg" :options="departments" style="width: 100%;"></b-form-select>
                            </div> -->
                            <!-- <div class="mt-6">
                                <textarea class="creditLoad2" style="width: 100%" v-model="editedItem.cdes" placeholder="Course Description" type="text" hint="Hint text" name="" id="" cols="10" rows="5"></textarea>
                            </div> -->
                            <!-- <div>
                            <v-checkbox v-model="isGs" label="GS course"></v-checkbox>
                        </div> -->
                        <v-card-action>
    
    <v-btn color="#c0e7fe" @click="save"  class="col-md-4" style="margin:auto" v-if="!activity">Add Course</v-btn>

    <v-btn color="#c0e7fe" @click="save"  class="col-md-5" style="margin:auto" disabled v-else> <v-progress-circular
      indeterminate
      color="#777" :size="25"
    ></v-progress-circular> &nbsp; Adding Course</v-btn>

                        </v-card-action>
                        </v-card-text>

                        </v-card>
                    </v-dialog>
                    <v-dialog persistent v-model="dialogEdit" max-width="500px">
                        <v-card class="" max-width="500">
                            <v-card-title style="color:#666; font-size:18px">
                                Edit Hosted Course
                                <v-spacer></v-spacer>
                                <v-icon @click="close">
                                    mdi-close-circle
                                </v-icon>
                            </v-card-title>
                            <v-divider style="margin-top:-3px"></v-divider>

                            <!-- <div style="display: flex; justify-content: space-between;">
                                <h4 class="title-h5 mb-4">Edit Hosted Course</h4>
                                <span style="color: red; cursor: pointer; font-size: 25px" @click="close">X</span>
                            </div> -->
                            <v-card-text>
                            <div class="mt-6">
                                <v-text-field outlined label="Course Title" v-model="edit_title" placeholder="Course Title" dense></v-text-field>
                                <v-text-field outlined label="Course Code" v-model="edit_code" placeholder="Course Code" dense></v-text-field>
                                <v-textarea outlined label="Course Description" v-model="edit_des" placeholder="Course Description" no-resize dense :rows="4"></v-textarea>

                                <!-- <input class="creditLoad" style="width: 100%" v-model="edit_title" placeholder="Course Title" type="text"> -->
                            </div>
                            <v-btn color="#c0e7fe" @click="editItem"  class="col-md-4" style="margin:auto">Edit Course</v-btn>

                                <!-- <button @click="editItem" class="button-sub2" type="submit">Edit Course</button> -->
                        </v-card-text>
                        </v-card>
                    </v-dialog>
                    <!-- Bulk upload modal -->
                    <v-dialog v-model="showImportModal" max-width="500">
                        <v-card>
                            <v-card-title style="color:#666; font-size:18px">

                            Bulk upload
                            <v-spacer></v-spacer>
                                <v-icon @click="showImportModal = false">
                                    mdi-close-circle
                                </v-icon>
                        </v-card-title>
                            <v-divider style="margin-top:-3px"></v-divider>
                            <v-card-text>
                            <div class="pa-6">

                                <v-file-input show-size required id="fileInput" MouseEvent @change="uploadcsv" label="Select file"></v-file-input>
                                <div style="text-align: center;">Click <a :href="templateLink">here</a> to download bulk upload template</div>

                                <!-- <v-file-input show-size required id="fileInput" outlined MouseEvent style="width: 100%"  @change="uploadcsv" label="Select file"></v-file-input> -->
                            </div>
</v-card-text>
                            <div class="pa-6">
                                <v-row>
                                    <v-col cols="3"></v-col>
                                    <v-col cols="6">

                                        <v-btn block @click="closeUploadModal" style="height: 45px;">Cancle</v-btn>
                                    </v-col>
                                    <!-- <v-col cols="6">
                                <v-btn block @click="processImportFile" style="background: #0F475F; height: 45px; color: #fff">Proceed</v-btn>

                                    </v-col> -->
                                </v-row>
                            </div>
                        </v-card>
                    </v-dialog>

<!-----UPLOAD OPTIONS-->

<v-dialog max-width="500" v-model="actionModal" persistent>
                        <v-card>
                            <v-card-title style="color:#666; font-size:18px">
                                Select upload type
                                <v-spacer></v-spacer>
                                <v-icon @click="actionModal=false">
                                    mdi-close-circle
                                </v-icon>
                            </v-card-title>
                            <v-divider style="margin-top:-3px"></v-divider>
                            <v-card-text>

                            <div class="pa-2 pt-0">
                                <div>
                                Choose your prefered choice for upload. <br>Bulk upload will allow you to upload a csv/xlxs file which contains the courses to upload.<br>  Single upload will give you the opportunity to add the courses one after the other.
                                </div>
                                <div class="pl-5">
                                    <v-radio-group v-model="actionType" column mandatory>
                                        <v-radio label="Bulk upload" value="bulk"></v-radio>
                                        <v-radio label="Single upload" value="single"></v-radio>

                                    </v-radio-group>
                                </div>

                                <v-card-actions class="pa-0">
          <div class="row">
            <!-- <v-btn color="primary" text @click="actionModal = false">
              Cancle
                </v-btn> 
             -->
              <v-spacer></v-spacer>
      
              <v-btn color="#c0e7fe" @click="uplaodAction"  class="col-md-4" >Next</v-btn>

              <!-- <v-btn block color="primary" @click="openProfileType">Next</v-btn> -->
          </div>
        </v-card-actions>

                             
                            </div>
                            </v-card-text>

                        </v-card>
                    </v-dialog>



                    <!-- IMPORT DIALOG MODAL -->
                    <v-dialog v-model="showImportData" max-width="900">
                        <div style="background-color: #0F475F; padding: .9rem; color: #fff">
                            Import Data
                        </div>
                        <v-card style="overflow: hidden">
                            <div class="pa-3">
                                <div v-if="badImportData.length == 0">
                                     <strong>{{badImportData.length}} Bad Data</strong>

                                <v-data-table :headers="importHeaders" :items="badImportData" :sort-desc="[false, true]" multi-sort :search="search" class="elevation-1">
                                    <template v-slot:item.ctitle="{item}">
                                        <span v-if="item.ctitle">{{item.ctitle}}</span>
                                        <span v-else style="color: red">Empty</span>
                                    </template>
                                    <template v-slot:item.cdes="{item}">
                                        <span v-if="item.cdes">{{item.cdes}}</span>
                                        <span v-else style="color: red">Empty</span>
                                    </template>
                                    <template v-slot:item.ccode="{item}">
                                        <span v-if="item.ccode">{{item.ccode}}</span>
                                        <span v-else style="color: red">Empty</span>
                                    </template>
                                    <template v-slot:item.sn="{ index}">
                                        {{index + 1}}
                                    </template>
                                </v-data-table>
                                </div>
                               <div class="mt-8">
                                    <strong>{{importData.length}} Good Data</strong>
                                <v-data-table :headers="importHeaders" :items="importData" :sort-desc="[false, true]" multi-sort :search="search" class="elevation-1">

                                    <template v-slot:item.sn="{ index}">
                                        {{index + 1}}
                                    </template>
                                </v-data-table>
                               </div>
                                <div class="row mt-4">
                                    <div class="col-1"></div>
                                    <div class="col-5">

                                        <v-btn block @click="closeImportData" style="">Cancle</v-btn>
                                    </div>
                                    <div class="col-5" v-if="!activity">
                                        <v-btn @click="uploadBulk" block style="background: #0F475F; color: #fff;">Import</v-btn>
                                    </div>

                                    <div class="col-5" v-else>
                                        <v-btn @click="uploadBulk" block style="background: #0F475F; color: #fff;"> <v-progress-circular
      indeterminate
      color="#777" :size="25"
    ></v-progress-circular> &nbsp; Importing Courses</v-btn>
                                    </div>

                                </div>
                            </div>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="showBadData" max-width="900" persistent>
                        <v-card style="overflow: hidden" >
                            <v-card-title style="color:#666; font-size:18px;">
                            Bad upload data (Find details below)
                                <v-spacer></v-spacer>
                                <v-icon @click="closeBadDataModal=false">
                                    mdi-close-circle
                                </v-icon>
                            </v-card-title>
                            <v-divider style="margin-top:-3px"></v-divider>
                            <p class="pa-3">{{badDataResponse}}</p>
                            <div class="pa-3">
                                <v-data-table :headers="importHeaders" :items="badData" :sort-desc="[false, true]" multi-sort :search="search" class="elevation-1">

                                    <template v-slot:item.sn="{ index}">
                                        {{index + 1}}
                                    </template>
                                </v-data-table>
                                <div class="row mt-4">
                                    <div class="col-3"></div>

                                    <div class="col-6">
                                        <v-btn @click="closeBadDataModal" block style="background: #0F475F; color: #fff;">Close</v-btn>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                    </v-dialog>
                   
                    <!-- ASK IF YOU ARE SURE TO DELETE -->
                    
                    <v-dialog v-model="dialogDelete" max-width="700px">
                        <v-card>
                            <v-card-title style="color:#666; font-size:18px">
                                        Delete Hosted Course
                                        <v-spacer></v-spacer>
                                <v-icon @click="dialogDelete=false">
                                    mdi-close-circle
                                </v-icon>
        </v-card-title>
        <v-divider style="margin-top:0px"></v-divider>
                           <v-card-text>
<v-alert type="info">
    Note:<br>
You cannot delete a hosted course that has been profiled by a department. Also not that this action is not reversible.
</v-alert><br>
<div>
    <!-- <span style="color:red; font-weight:600">Note that this action is not reversible</span><br> -->
    <p style="font-size:16px">To delete this course, type the course code in the input field bellow. 
        <!-- <span style="color:red; font-weight:600">Note that this action is not reversible</span> -->
    </p>
    <v-text-field v-model="codetoDel" label="Course code" outlined class="col-md-6" hint="" persistent-hint>
    </v-text-field>
</div>
                           </v-card-text>

                            <v-card-actions style="margin-top:-20px; padding-bottom:20px">                                
                                <v-btn color="error" @click="deleteItem" :disabled="tobedeleted==false" class="col-md-5">Delete</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <!-- <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItem">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog> -->
                </div>
                <v-card elevation="0">
                    <div class="row">
                    <div class="col-md-2">
            
        </div>
        <v-spacer></v-spacer>
                   
                </div>
                    <!-- <div>
                        <h3>Host Course for {{depSearch.value.name}} Department</h3>
                    </div> -->
                    <!-- <div style="background:#006794; padding: 15px 20px; color:#fff; margin-top: 20px">{{depSearch.value.name}} Hosted Courses</div> -->
                    <!-- <v-card-title>
                        <strong>{{desserts.length}} Hosted Courses</strong>
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                    </v-card-title> -->
                    
                </v-card>
            </div>
        </div>
    </div>
    </div>
</div>
</template>

<script>
import Swal from 'sweetalert2';
import axios from 'axios';
import readXlsxFile from "read-excel-file";

export default {
    // mixins: [requestsMixin],
    data() {
        return {
            activity:'',
            templateLink:`${this.$uploadTemplate}`,
            programSearch: '',
            depSearch: '',
            departmentName: '',
            workingdept: '',
            actionType: '',
            dialog: false,
            dialogDelete: false,
            tobedeleted:false,
            showImportData: false,
            actionModal: false,
            edit_title: '',
            showTable: true,
            edit_code: '',
            department_edit: '',
            badDataResponse: '',
            ////// code to delete
            codetoDel:'',
            programsArr: [{
                text: 'Test Program',
                value: '2232399238'
            }],
            badData: [],
            showBadData: false,
            programs: [],
            departmentsArr: [],
            searchSepartment: '',
            departmentSearch: [],
            isGs: false,
            showImportModal: false,
            edit_des: '',
            department: null,
            departments: [{
                text: 'Select Department',
                value: null
            }],
            importHeaders: [{
                    text: 'S/N',
                    value: 'sn'
                },

                {
                    text: 'Course Title',
                    align: 'start',
                    sortable: false,
                    value: 'ctitle',
                },
                {
                    text: 'Course Code',
                    value: 'ccode'
                },
                {
                    text: 'Course Description',
                    value: 'cdes'
                },
            ],
            importData: [],
            badImportData: [],
            items: [{
                    text: 'Dashboard',
                    disabled: false,
                    href: '/#/dashboard',
                },
                {
                    text: 'Department',
                    disabled: false,
                    href: '/#/department/addcourse',
                },
                {
                    text: 'Add Course',
                    disabled: true,
                    href: 'breadcrumbs_link_2',
                },
            ],
            headers: [{
                    text: 'S/N',
                    value: 'sn'
                },

                {
                    text: 'Course Title',
                    align: 'start',
                    sortable: false,
                    value: 'ctitle',
                },
                {
                    text: 'Course Code',
                    value: 'ccode'
                },
                {
                    text: 'Course Description',
                    value: 'cdes'
                },
                {
                    text: 'Actions',
                    value: 'actions'
                },

            ],
            // programArr: [],
            item: null,
            dialogEdit: false,

            editedItem: {
                sn: '',
                ctitle: '',
                ccode: '',
                cdes: '',
            },
            desserts: [],
            courseCode: '',
            courseTitle: '',
            courseDescription: '',
            search: '',
            bulkUpload: false,
            singleUpload: false,
            // key: value
        }
    },
    created() {

    },
    mounted(){

       this.programSearch = this.$router.currentRoute.params.activeSetting.data.data.program._id;
    //    this.$router.currentRoute.params.activeDepartments.data.message.forEach(dep => {
    //     this.departmentsArr.push({
    //                         text: dep.name,
    //                         value: {
    //                             id: dep._id,
    //                             name: dep.name,
    //                             code: dep.code
    //                         }
    //                     });
    //    });
    this.allowedMenu = this.$router.currentRoute.params.getmyMenus;

     /// filter the departments this user is supposed to operate as and use as department array
       this.$router.currentRoute.params.activeDepartments.data.message.forEach(dep => {
        this.allowedMenu.department.some((f) => {
          if(f == dep._id){
        this.departmentsArr.push({
                            text: dep.name,
                            value: {
                                id: dep._id,
                                name: dep.name,
                                code: dep.code
                            }
                        });
                      }
                    })
       });
       console.log(this.departmentsArr);
       this.departmentSearch = this.departmentsArr;
      // this.departments = this.$router.currentRoute.params.activeDepartments.data.message;
      
    },
    watch: {
        codetoDel: {
      handler: function(val) {
        console.log(val);
        console.log(this.item.ccode);
        if(this.item){
        if(val == this.item.ccode){
this.tobedeleted = true
        }else{
            this.tobedeleted = false
        }
        /* ... */
      }
    },
      immediate: true
    }
  },
    beforeMount() {
        // this.departmentName = this.$route.params.deptname;
        // const menu = [{
        //     staff_id: "NAU5357",
        //     menu_name: "Department",
        //     menu_id: "1234dr3r",
        //     has_submenu: true,
        //     sub_menu: [{
        //         submenu_name: "Courses",
        //         submenu_icon: "sdssd",
        //         submenu_link: "/addcourse",
        //         submenu_id: "898uj-98uj",
        //         defaultFirst: false,
        //         programs: ["61ef4514a88631b45cd21f31"],
        //         departments: ["61d7902565e95899bcf1a394", "61d7902565e95899bcf1a397"],
        //         status: 1
        //     }]
        // }];
       // this.checkAdminType(menu);

    },
    methods: {
        closeImportData() {
            this.showImportData = false;
            this.importData = [];
        },
        closeUploadModal() {
            this.showImportModal = false;
            console.log(document.getElementById('fileInput').value)
            document.getElementById('fileInput').value = null;

        },
        processImportFile() {
            console.log('imported')
        },
        closeBadDataModal() {
            this.badData = [];
            this.showBadData = false;
            this.badDataResponse = '';
        },
        async uploadBulk() {
            this.activity = true
            const data = this.importData.map(course => {
                return {
                    course_code: course.ccode,
                    createdBy: 'skolarsuite',
                    name: course.ctitle,
                    description: course.cdes,
                    offeringDepartment: this.depSearch.value.id,
                    programId: this.programSearch
                };

            })
            console.log(data);
            var url = `${this.$appUrl}/add-course`

            const res = await axios.post(url, {
                course: [...data]
            });
            this.showImportData = false;
            if (res.data.code == 's200') {
                this.activity = false

                const badData = res.data.badData;
                const goodData = res.data.goodData;
                if (badData.length !== 0) {
                    this.badDataResponse = res.data.message;
                    this.showBadData = true;
                    badData.forEach(el => {
                        this.badData.push({
                            ccode: el.course_code,
                            ctitle: el.name,
                            cdes: el.description,
                            sn: this.desserts.length + 1,
                            id: el._id
                        })
                    })
                } else {
                    goodData.forEach(el => {
                        this.desserts.push({
                            ccode: el.course_code,
                            ctitle: el.name,
                            cdes: el.description,
                            sn: this.desserts.length + 1,
                            id: el._id
                        })
                    })
                    return Swal.fire({
                        icon: 'success',
                        text: res.data.message,
                        title: 'Uploaded successfully'
                    })
                }
            } else {
                this.activity = false

                Swal.fire({
                    icon: 'error',
                    text: res.data.message,
                    title: 'Oops..'
                })
            }

        },
        uploadcsv() {
            //Read the excel file
            this.importData = [];
            const arr = [];
            const badArr = [];
            const input = document.getElementById('fileInput');

            //    console.log('Filllllllll', input.files[0]);

            if (input.files[0].name.split('.')[1] !== 'xlsx') {
                Swal.fire({
                    icon: 'error',
                    text: 'Wrong file! Please upload an excel file',
                    title: 'Oops..'
                })
            }
            readXlsxFile(input.files[0]).then((rows) => {
                this.badImportData = [];
                this.importData = [];
                const headers = rows[0];
                //    Validate Headers
                if (
                    headers[0] !== 'Course_Name' ||
                    headers[1] !== 'Course_Code' ||
                    headers[2] !== 'Course_Description'
                ) {
                    const message = `Worng Course Template Format \n Header should be in this format \n Course_Name | Course_Code | Course_Description`
                    return Swal.fire({
                        icon: 'error',
                        title: 'Oops..',
                        text: message
                    })
                } else {
                    //Remove the first row
                    rows.shift();
                    for (const row of rows) {
                        if (row.includes(null)) {

                            row['ctitle'] = row[0];
                            delete row[0];
                            row['ccode'] = row[1];
                            delete row[1];
                            row['cdes'] = row[2]
                            delete row[2]

                            // console.log('Here')

                            badArr.push(row);
                        }else{

                            row['ctitle'] = row[0];
                            delete row[0];
                            row['ccode'] = row[1];
                            delete row[1];
                            row['cdes'] = row[2]
                            delete row[2]
    
                            console.log('Here')
                        arr.push(row);
                        }


                    }
                    this.showImportData = true;
                    this.showImportModal = false;

                    arr.forEach(course => {
                        this.importData.push({
                            ...course
                        })
                    })
                    badArr.forEach(course => {
                        this.badImportData.push({
                            ...course
                        })
                    })
                }

            })

        },
        openActionModal() {
            this.actionModal = true;
        },
        uplaodAction() {
            this.actionModal = false;
            if (this.actionType == 'bulk') {
                this.showImportModal = true;
            }

            if (this.actionType == 'single') {
                this.dialog = true;
            }
        },
        moveBack() {
            this.showTable = true;
            this.depSearch = '';
        },


        async checkAdminType(menu) {
            let staffLevel = '1000'
            //Fetch departments
            // await this.fetchCourses();

            //fetch programs
            // await this.fetchProgram();
            //check admin type
            if (`${this.$logedInLevel}` == staffLevel) {
                // this.programs.push(...this.programsArr);
                this.departmentSearch =this.departmentsArr

                console.log(this.departmentSearch)
                return;
            }

            //Get route path
            const path = this.$route.path;
            //find the submenu in menu

            let submenuData = null;
            //Filter out current submenu
            menu.forEach(adminmenu => {
                console.log('menussss',adminmenu);

                adminmenu.sub_menu.forEach(submenu => {
                    console.log('submenussss',submenu);
                    if (submenu.submenu_link === path) {
                        submenuData = submenu;
                        return;
                    }
                })
            })

            console.log('SUBBBBBBBB', path, submenuData);

            //Compare programs
            for (let i = 0; i < this.programsArr.length; i++) {
                for (let j = 0; j < submenuData.programs.length; j++) {
                    if (this.programsArr[i].value.id == submenuData.programs[j]) {
                        this.programs.push(this.programsArr[i]);
                    }
                }
            }

            //Compare push departments
            for (let i = 0; i < this.departmentsArr.length; i++) {
                for (let j = 0; j < submenuData.departments.length; j++) {
                    console.log('REACHED', this.programsArr[i]);
                    if (this.departmentsArr[i].value.id == submenuData.departments[j]) {
                        this.departmentSearch.push(this.departmentsArr[i]);
                    }
                }
            }

        },
     
        pushtoAsign(item) {
            const data = {
                item,
                department: this.depSearch,
                program: this.programSearch
            }
            localStorage.setItem('courrrrrrse', JSON.stringify(data))
            this.$router.push({
                name: 'AssignCourse',
            });
        },
        nextfromDept() {
            if (this.department != '') {
                this.departmentPresent = true;
            }
        },

    

        async addCoursecall() {
            const {
                data
            } = await this.addCourses();
            console.log(data);
            this.$swal('Hello Vue world!!!');
        },
        close() {
            this.dialog = false;
            this.dialogEdit = false;
            // this.$nextTick(() => {
            //     this.editedItem = Object.assign({}, this.defaultItem)
            //     this.editedIndex = -1
            // })
        },
        async save() {
                                                                // start activity spining and disable button
            this.activity = true
            console.log(this.editedItem);
            if (this.editedItem.ctitle == '' ) {
                this.activity = false
                return Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Provide Course Title',
                    width: 450,
                })
            } 

            if (this.editedItem.ccode == '' || this.editedItem.cdes == '') {
                this.activity = false
                return Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Provide Course Code',
                    width: 450,
                })
            } 

            if (this.editedItem.cdes == '') {
                this.activity = false
                return Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Provide Course Description',
                    width: 450,
                })
            } 

                if (this.editedIndex > -1) {
                    Object.assign(this.desserts[this.editedIndex], this.editedItem)
                } else {
                    try {
                        const data = {
                            course_code: this.editedItem.ccode,
                            isGsCourse: this.isGs,
                            createdBy: 'skolarsuite',
                            name: this.editedItem.ctitle,
                            description: this.editedItem.cdes,
                            offeringDepartment: this.depSearch.value.id,
                            programId: this.programSearch
                        }
                        var url = `${this.$appUrl}/add-course`
                        const res = await axios.post(url, {
                            course: [data]
                        });
                        console.log('RES', res, this.editedItem);
                        if (res.data.code === 's200') {
                                                    // stop activity spining and enable button
                            this.activity = false

                            // this.desserts = [];
                            const badData = res.data.badData;
                            console.log()
                            if (badData.length !== 0) {
                                this.badDataResponse = res.data.message;
                                this.showBadData = true;
                                badData.forEach(el => {
                                    this.badData.push({
                                        ccode: el.course_code,
                                        ctitle: el.name,
                                        cdes: el.description,
                                        sn: this.desserts.length + 1,
                                        id: el._id
                                    })
                                })
                            } else {
                                this.desserts.push({
                                    ccode: data.course_code,
                                    ctitle: data.name,
                                    cdes: data.description,
                                    sn: this.desserts.length + 1,
                                    id: data._id
                                })
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Successful',
                                    text: res.data.message
                                });
                            }
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops..',
                                text: res.data.message
                            })
                        }

                        this.dialog = false;
                        this.editedItem.ccode = '';
                        this.editedItem.ctitle = '';
                        this.editedItem.cdes = '';
                        this.department = null;

                        return;

                    } catch (error) {
                        // stop spining and enable button
                        this.activity = false
                        console.log(error);
                    }
                }

                this.close()
        
        },

        async listCourses() {
            this.activity = true
console.log(this.depSearch);
            try {
                if (!this.depSearch || !this.programSearch){
this.activity = false
                return Swal.fire({
                    icon: 'info',
                    text: 'Please select Program and Department',
                    title: 'Oops..'
                });
            }
                var url = `${this.$appUrl}/get-courses?programId=${this.programSearch}&offeringDepartment=${this.depSearch.value.id}`
                const res = await axios.get(url);
                console.log('HERE', res);
                if (res.data.code == 's200') {

                    this.showTable = false;
                    this.desserts = [];
                    res.data.message.forEach((course) => {
                        this.desserts.push({
                            ctitle: course.name,
                            ccode: course.course_code,
                            cdes: course.description,
                            department: course.offeringDepartment,
                            id: course._id
                        })
                    })
                } else {
                    this.showTable = false;
                    Swal.fire({
                        icon: 'info',
                        text: res.data.message,
                        title: 'Oops..'
                    })
                }
                this.activity = false

            } catch (error) {
                this.activity = false
                console.log(error);
            }
        },

        showEdit(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.item = item;

            this.dialogEdit = true;
            this.edit_code = item.ccode;
            this.edit_title = item.ctitle;
            this.edit_des = item.cdes;
            this.department_edit = item.department
        },

        async editItem() {
            let item = this.item;
            try {
                const data = {
                    initial_code: item.ccode,
                    course_code: this.edit_code,
                    description: this.edit_des,
                    name: this.edit_title,
                }
                var url = `${this.$appUrl}/edit-course`
                const res = await axios.post(url, data);
                console.log(res);
                if (res.data.code === 's200') {
                    console.log('ITem', item)
                    // this.editedItem = Object.assign({}, item);
                    const index = this.desserts.findIndex(el => el.ccode === item.ccode);
                    this.desserts[index].ccode = this.edit_code;
                    this.desserts[index].ctitle = this.edit_title;
                    this.desserts[index].cdes = this.edit_des;
                    this.desserts[index].department = this.depSearch;
                    this.dialogEdit = false;
                    Swal.fire({
                        icon: 'success',
                        title: 'Successful',
                        text: res.data.message
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops..',
                        text: res.data.message
                    })
                }
            } catch (error) {
                console.log(error);
            }
        },

        showDelete(item) {
            this.item = item;
            this.editedIndex = this.desserts.indexOf(item)
            this.dialogDelete = true
            this.codetoDel=''
        },

        async deleteItem() {
            var url = `${this.$appUrl}/delete-course`
            try {
                const res = await axios.post(url, {
                    course_code: this.item.ccode,
                    program: this.programSearch,
                });

                if (res.data.code === 's200') {
                    this.desserts.splice(this.editedIndex, 1);
                    this.dialogDelete = false;
                    Swal.fire({
                        icon: 'success',
                        title: 'Successful',
                        text: 'Course successfully deleted'
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops..',
                        text: res.data.message
                    })
                }
            } catch (error) {
                console.log(error);
            }
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        deleteItemConfirm() {
            this.desserts.splice(this.editedIndex, 1)
            this.closeDelete()
        },
    },

}
</script>

<style>

</style>
