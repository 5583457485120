import  axios  from "axios";
export const axiosGet = axios.create(
    {
        method: 'GET',
        withCredentials: true ,
    },

)

export const axiosPost = axios.create(
    {
        method: 'POST',
       withCredentials: true,
      
    },
)


//we intercept every requests 
axiosGet.interceptors.request.use(async function(config){
    //anything you want to attach to the requests such as token 
    return config;
}, error => {
    return Promise.reject(error)
})


//we intercept every response
axiosGet.interceptors.response.use(async function(config){
    
    return config;
}, error => {
//check for authentication or anything like that
    return Promise.reject(error)
})


//we intercept every requests 
axiosPost.interceptors.request.use(async function(config){
    //anything you want to attach to the requests such as token 
    return config;
}, error => {
    return Promise.reject(error)
})


//we intercept every response
axiosPost.interceptors.response.use(async function(config){
    
    return config;
}, error => {
//check for authentication or anything like that
    return Promise.reject(error)
})