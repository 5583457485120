<template>
  <div class="home">
    <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
            <span style="color:#777; font-size:16px;padding:10px;">PROFILE COURSES </span>
        </div>
    </div>
    <!-- <div v-if="options" class="profileCourse"> -->
        <div style="background: #f5f5f5; margin-top:150px; " class=" container-fluid row ">
            <div style="margin:auto; padding:20px; margin-top:100px" class="col-md-5"  v-if="!details">
                <v-card elevation="3" height="auto">
                    <!-- <v-card-title style="color:#666; font-size:18px">
                        Select Department
                    </v-card-title> -->
                    <v-divider style="margin-top:0px"></v-divider>

                    <v-col cols="11" style="margin:auto;padding-top:45px" pa-4>
                        <!-- {{departmentSearch}} -->
                        <v-autocomplete v-model="department" :items="departments" style="max-width: 100% !important" outlined label="Select Department" ></v-autocomplete>
                        <v-autocomplete v-model="degree" :items="degrees" style="max-width: 100% !important" outlined label="Select Degree"></v-autocomplete>
                        <v-autocomplete v-model="level" :items="levels" style="max-width: 100% !important" outlined label="Select Level"></v-autocomplete>
                        <v-autocomplete v-model="semester" :items="semesters" style="max-width: 100% !important" outlined label="Select Semester"></v-autocomplete>

                        <!-- <v-text-field outlined label="Enter staff CIRMS ID / staff ID" v-model="staffId" placeholder="CIRMS ID / Staff ID"></v-text-field> -->
                        <v-card-actions>
                        <v-btn color="#c0e7fe" @click="proceedToProfilingType" :disabled="staffId==''" v-if="!activity" class="col-md-7 ma-auto" >Next</v-btn>
                        <v-btn color="primary"  elevation='2' class="col-md-7 ma-auto"  style="background-color:#e0e0e0 !important;color:#444  !important;padding:10px;cursor: pointer !important; " disabled v-else> <v-progress-circular
      indeterminate
      color="#777" :size="25"
    ></v-progress-circular> &nbsp;Processing</v-btn>
                        </v-card-actions>
                    </v-col>
                </v-card>
            </div> 
    <div class="col-md-12 ma-auto mt-10">
        <span
          v-if="showRadio & details"
          class="mb-5 d-block"
          @click="Back"
          style="cursor: pointer"
        >
          <v-icon>mdi-arrow-left-circle</v-icon>
          Back
        </span>
        <span
          v-if="!showRadio && details"
          class="mb-5 d-block"
          @click="radioBack"
          style="cursor: pointer"
        >
          <v-icon>mdi-arrow-left-circle</v-icon>
          Back
        </span>
      <v-card v-if="details" class="table-selected">
        <!-- <v-card v-if="details" class="table-selected"> -->
            <v-card-title style="color:#666; font-size:18px">
                Profiling {{ level.name }}Level {{ semester.name }} Semester Course
              For {{ department.name }} {{ activeSession.text }} Session
            </v-card-title>
      <v-divider></v-divider>
       
        <!-- <div class="container"> -->
        <div class="mt-6 row pa-10 pt-2" >
          <div v-if="showRadio" class="col-lg-4 col-md-6 col-sm-12">
            <v-radio-group v-model="selective">
              <v-radio label="Profile Single Course" :value="false"></v-radio>
              <v-radio label="Profile Selective Course" :value="true"></v-radio>
            </v-radio-group>
            <v-card-actions>
                        <v-btn color="#c0e7fe" @click="showForm"  class="col-md-7 ma-auto" >Next</v-btn>
                        </v-card-actions>

          </div>
          <div class="col-lg-4" v-if="showSingleProfile">
            <h6>Profile single course</h6><br>
            <div class="table-selected2">
              <div style="">
                <v-autocomplete
                  clearable
                  outlined
                  :items="courses"
                  id="my-select"
                  v-model="course"
                  label="List of Courses"
                ></v-autocomplete>
              </div>
              <div style="">
                <v-autocomplete
                  clearable
                  outlined
                  :items="units"
                  v-model="unit"
                  label="Credit Unit"
                ></v-autocomplete>
              </div>
              <div style="margin-top:-30px">
                <v-checkbox
                  v-model="isGs"
                  label="Set as GS course"
                ></v-checkbox>
              </div>
              <div style="margin-top:-30px">
              <v-checkbox v-model="compulsory" label="Compulsory"></v-checkbox>
              </div>
              <!-- <v-checkbox label="Selective" v-model="selective" @change="show"></v-checkbox> -->
              <v-card-actions>
                <v-btn color="#c0e7fe" @click="save" :disabled="staffId==''" v-if="!activity" class="col-md-7 ma-auto" >Profile Course</v-btn>

            </v-card-actions>
            </div>
          </div>
          <div v-if="showSelective" class="col-md-4">
            <div class="row">
              <div class="col-md-6">
            <h6>Profile Selective </h6>
          </div>
          <div class="col-md-6" style="text-align:right">
           <v-icon @click="add" style="color:green">mdi-plus-circle</v-icon> <span @click="add" style="cursor:pointer; color:green">Add selective</span>
          </div>
          </div><br>
            <div style="height:auto;max-height: 600px; overflow-y: scroll">
              
              <div
                v-for="(input, index) in inputs"
                :key="index"
                class="table-selected2"
              >
                <v-autocomplete
                  label="Choose selective course"
                  clearable
                  outlined
                  v-model="input.name"
                  :items="courses"
                
                ></v-autocomplete>
                <!-- <v-select style="text-align: left;" v-model="input.name" :items="courses" chips class="mt-4" label="Selective Courses"></v-select> -->
                <!-- <v-checkbox v-model="input.Compulsory" label="Compulsory"></v-checkbox> -->
                <div class="">
                    <v-autocomplete
                  clearable
                  outlined
                  :items="units"
                  v-model="input.CreditLoad"
                  label="Credit Unit"
                  id="creditLoad"
                  class="creditLoad"
                ></v-autocomplete>
                    <!-- <input
                      type="number"
                      v-model=""
                      class="creditLoad"
                      id="creditLoad"
                      placeholder="Credit Load"
                    /> -->
                  <!-- <v-checkbox
                    v-model="input.isGs"
                    label="GS Course?"
                  ></v-checkbox> -->
                  <div class="" style="text-align: right">
                    <v-btn small
                      v-if="index !== 0"
                      color="red"
                      style="color: #fff"
                      @click="removeSelective(index)"
                    >
                      <v-icon>
                        mdi-trash-can-outline
                      </v-icon>Delete
                    </v-btn>
                  </div>
<v-divider></v-divider>
                 
                </div>
              </div>
            </div>
            <div class="">
                <v-spacer></v-spacer>
                <!-- <v-btn color="primary" text @click="add">
                  Add Selective Course
                </v-btn> -->
                <v-btn color="primary" v-if="isEdit" @click="editSelectable"
                  >Edit</v-btn
                >
                <v-btn color="#c0e7fe" @click="save2" class="col-md-7 ma-auto" >Profile Course</v-btn>
                <!-- <v-btn color="primary" v-else @click="save2"> Submit </v-btn> -->
              </div>
          </div>
          <div class="col-lg-8" style="border-left:1px solid #ccc">
            <div class="table-selected2">
              <div>
                <h6>
                    You have profiled {{ desserts.length }} Courses
                  <!-- List {{ level.name }}Level {{ semester.name }} Semester Course
                  For {{ department.name }} -->
                </h6>
                <div class="row" style="margin:0;padding:0; margin-bottom:15px">
                  <div class="col-md-9"></div>
                  <div class="col-md-3 indicatorkey">Status Keys
                    <v-divider style="margin:0; margin-bottom:10px"></v-divider>

              <div class="indicatorkey"><span class="dot-red"></span> Not approved</div> <div class=" indicatorkey"><span class="dot-amber"></span> Awaiting Approval</div> <div class=" indicatorkey"><span class="dot-green"></span> Approved</div>
              </div>
            </div>
              </div>
              <div>
                <!-- <h4>{{ desserts.length }} Courses</h4> -->
              </div>
              <!-- <v-data-table v-model="selected" :headers="headers" :items="desserts" item-key="id"
                                show-select class="elevation-1" @toggle-select-all="selectAllToggle">
                                <template v-slot:item.data-table-select="{ item, isSelected, select }">
                                    <v-simple-checkbox v-if="item.disabled" :value="isSelected"
                                        :readonly="item.disabled" @input="select($event)">
                                    </v-simple-checkbox>
                                </template>
                                <template v-slot:item.stat="{ item }">
                                    <span>{{ `${item.stat == 2 ? 'Awaiting for approval' : item.stat == 1 ? 'Not approved' : 'Approved'}`}}</span>
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <v-icon small class="mr-2" @click="showEdit(item)">
                                        mdi-cog
                                    </v-icon>
                                    <v-icon v-if="item.status !== 3" small @click="deleteItem(item)">
                                        mdi-delete
                                    </v-icon>
                                </template>
                            </v-data-table> -->
                            <v-data-table v-model="selected" :headers="headers" :items="desserts" item-key="id" show-select class="elevation-1" @toggle-select-all="selectAllToggle">
                            <template v-slot:item.data-table-select="{ item, isSelected, select }">
                                <v-simple-checkbox v-if="item.disabled" :value="isSelected" :readonly="item.disabled" @input="select($event)">
                                </v-simple-checkbox>
                            </template>
                            <template v-slot:item.stat="{ item }">
                                  <div v-if="item.status == 1">
                                    <span class="dot-red"></span>
                                    </div>
                                    <div v-if="item.status == 3">
                                      <span class="dot-green"></span>
                                    </div>
                                    <div v-if="item.status == 2">
                                      <span class="dot-amber"></span>
                                    </div>
                                </template>
                                <template v-slot:item.gs="{ item }">
                                  <div v-if="item.gs == true">
                                    Yes
                                    </div>
                                    <div v-if="item.gs == false">
                                      No
                                    </div>
                                </template>
                            <!-- <template v-slot:item.stat="{ item }">
                                <span>{{ `${item.stat == 2 ? 'Awaiting for approval' : item.stat == 1 ? 'Not approved' : 'Approved'}`}}</span>
                            </template> -->
                            <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2" @click="showEdit(item)">
                                    mdi-cog
                                </v-icon>
                                <v-icon small @click="deleteItem(item)">
                                    mdi-delete
                                </v-icon>
                                
                            </template>
                        </v-data-table>
              <!-- <v-data-table v-model="selected" :single-select="false" show-select item-key="id"
                                :headers="headers" :items-per-page="20" dense :items="desserts"
                                @toggle-select-all="selectAllToggle">
                                <template v-slot:item.data-table-select="{ item, isSelected, select }">
                                    <v-simple-checkbox :value="isSelected" :readonly="!item.isSelectable" color=""
                                        :disabled="item.isSelectable" @input="select($event)">
                                    </v-simple-checkbox>
                                </template>
                                <template v-slot:item.stat="{ item }">
                                  <div v-if="item.status == 1">
                                    <span class="dot-red"></span>
                                    </div>
                                    <div v-if="item.status == 3">
                                      <span class="dot-green"></span>
                                    </div>
                                    <div v-if="item.status == 2">
                                      <span class="dot-amber"></span>
                                    </div>
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <v-icon small class="mr-2" @click="showEdit(item)">
                                        mdi-pencil
                                    </v-icon>
                                    <v-icon v-if="item.status !== 3" small @click="deleteItem(item)">
                                        mdi-delete
                                    </v-icon>
                                </template>

                            </v-data-table> -->

                            <v-card-actions>
                                <v-btn color="#c0e7fe" @click="sendForApproval" :disabled="staffId==''" v-if="!activity" class="col-md-7 ma-auto" >Send For Approval</v-btn>
                                <v-btn color="primary"  elevation='2' class="col-md-7 ma-auto"  style="background-color:#e0e0e0 !important;color:#444  !important;padding:10px;cursor: pointer !important; " disabled v-else> <v-progress-circular
      indeterminate
      color="#777" :size="25"
    ></v-progress-circular> &nbsp;Processing</v-btn>

             
            </v-card-actions>
            </div>
          </div>
        </div>
        <v-dialog v-model="dialogDelete" max-width="600px">
          <v-card>
            <v-card-title class="text-h5"
            
              >
              <h5>Delete Profiled Course</h5>
            </v-card-title>
            <v-divider style="margin:0; margin-bottom:50px"></v-divider>
          <v-card-text>
              <div v-if="item">
                <div v-if="!item.disabled">
              <v-alert  type="info">
You are about to make a request for an approved course to be deleted. Note that this request will be subjected to approval by Management before the request will be effected. Click Ok to send request for this profiled course to be deleted.
              </v-alert>
              <v-textarea label="Reason for Deleting an approved course" v-model="reasontoD" dense outlined></v-textarea>

            </div>
            <div v-else class="m-auto">
              <h5>Are you sure you want to delete this course item?</h5>
            </div>
            </div>
             
          </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- </div> -->
        <v-dialog v-model="dialogEdit" width="500">
          <v-card>
            <div class="header pa-5" style="">
              <h5>Edit Course</h5>
            </div>
            <v-divider style="margin:0px"></v-divider>
            <div class="pa-4">
              <div v-if="item">
                <div v-if="!item.disabled">
              <v-alert  type="info">
You are about to make a request for an approved course to be Edited. Note that this request will be subjected to approval by Management before the request will be effected. Click Send Update for Approval to send request for this profiled course to be edited.
              </v-alert>
              </div>
              </div>
              
              <div class="mb-3 row">
                <div class="m-auto mt-5 col-lg-8">
                  <v-text-field 
                    type="number" label="Credit Load" dense
                    v-model="unit_edit"
                    class="creditLoad"
                    id="creditLoad"
                    placeholder="Credit Load" 
                  />
                  <v-checkbox v-model="isGs_edit" label="Is GS course"></v-checkbox>
                </div>
              </div>
                           <!-- <v-btn
                color="#006794"
                style="height: 40px; color: #fff"
                class="mb-4"
                block
                @click="editCourse"
              >
                Send Update for Approval
              </v-btn>
              <v-btn style="height: 40px" block @click="dialogEdit = false">
                Cancel
              </v-btn> -->
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogEdit = false"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="editCourse"
                >Send Update for Approval</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
            </div>
          </v-card>
        </v-dialog>
      </v-card>
    </div>
    <v-dialog width="500" v-model="shownext">
      <v-card style="overflow: hidden">
        <v-card-title style="color:#666; font-size:18px">
          Course Profile Options
        </v-card-title>
        <v-divider style="margin-top:-3px"></v-divider>
        <v-card-text>
        <div class="pa-2">
          <v-radio-group v-model="profileType">
            <v-radio label="Start with an new course profile template" :value="true"></v-radio>
            <v-radio
              v-if="showImportField"
              label="Import course profile from another session "
              :value="false"
            >
            </v-radio>
          </v-radio-group>
        </div>
      </v-card-text>
      <v-card-actions class="ma-5">
          <div class="row">
            <v-btn color="primary" text @click="shownext = false">
              Cancle
                </v-btn> 
            
              <v-spacer></v-spacer>
      
              <v-btn color="#c0e7fe" @click="openProfileType"  class="col-md-4" >Next</v-btn>

              <!-- <v-btn block color="primary" @click="openProfileType">Next</v-btn> -->
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog width="500" v-model="showImport">
      <v-card style="overflow: hidden">
        <v-card-title style="color:#666; font-size:18px">
          Import profiled courses
        </v-card-title>
        <v-divider style="margin-top: 0"></v-divider>

        <div class="pa-10">
          <div class="mb-5">
            <v-select
              :items="sessions"
              v-model="session"
              label="Select session to import data from"></v-select>
          </div>
          <div>
            <v-btn
              color="#006798"
              style="color: #fff !important"
              @click="handleFetchProfileCourse"
              block>
              Next</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showImportedTable" width="900">
      <v-card style="overflow: hidden">
        <v-card-title style="color:#666; font-size:18px">
          {{ session }} Profiled Coursess
        </v-card-title>
      <v-divider style="margin-top: 0"></v-divider>
        <v-data-table
          :headers="headers"
          dense
          :items="importData"
          sort-by="calories"
          class=""
        >
        </v-data-table>
        <div class="my-5 row">
          <div class="col-3"></div>
          <div class="col-3">
            <v-btn color="" @click="showImportedTable = false" block
              >Cancle</v-btn
            >
          </div>
          <div class="col-3">
            <v-btn
              color="#006798"
              style="color: #fff !important"
              :disabled="importData.length == 0"
              @click="handleImport"
              block
              >Import
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
  </div>

</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
export default {
  data() {
    return {
      activity:false,
      isGs: false,
      course_edit: null,
      compulsory_edit: null,
      unit_edit: null,
      showSelective: false,
      isGs_edit: null,
      isEdit: false,
      showSingleProfile: false,
      program: "",
      profileType: true,
      selected: [],
      degrees: [
        {
          value: null,
          text: "Degrees",
        },
      ],
      degree: "",
      units:[{
        value:'0',
        text:'0'
      },
        {
        value:1,
        text:1
      },{
        value:2,
        text:2
      },{
        value:3,
        text:3
      },{
        value:4,
        text:4
      },{
        value:5,
        text:5
      },{
        value:6,
        text:6
      },{
        value:14,
        text:14
      }
    ],
      inputs: [
        {
          name: "",
          courseDescription: "Course Discription",
          CreditLoad: "",
          isGs: false,
        },
      ],
      showImport: false,
      showRadio: true,
      details: false,
      item: null,
      course: null,
      showImportedTable: false,
      options: true,
      dialog: false,
      selective: false,
      unit: null,
      shownext: false,
      dialogDelete: false,
      showImportField: true,
      dialogEdit: false,
      disabledCount: 0,
      checkbox: null,
      courseSelect: [],
      courseHolder: [],
      value: [],
      importData: [],
      departmentsArr: [],
      department: null,
      departments: [],
      level: null,
      compulsory: false,
      semester: null,
      reasontoD:'',
///// holds the menus,departments and also the route that is allowed for this user
allowedMenu:'',

      levels: [
       
        {
          value: {
            name: "100",
            val: 1,
          },
          text: "100",
        },
        {
          value: {
            name: "200",
            val: 2,
          },
          text: "200",
        },
        {
          value: {
            name: "300",
            val: 3,
          },
          text: "300",
        },
        {
          value: {
            name: "400",
            val: 4,
          },
          text: "400",
        },
        {
          value: {
            name: "500",
            val: 5,
          },
          text: "500",
        },
        {
          value: {
            name: "600",
            val: 6,
          },
          text: "600",
        },
      ],
      semesters: [
        {
          value: {
            name: "First",
            val: 1,
          },
          text: "First",
        },
        {
          value: {
            name: "Second",
            val: 2,
          },
          text: "Second",
        },
      ],
      sessions: ['2021/2022'],
      session: "",
      courses: [],
      headers: [
        {
          text: "Course Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Course Code",
          value: "courseCode",
        },
        {
          text: "Semester",
          value: "semester",
        },
        {
          text: "Gs course",
          value: "gs",
        },
        {
          text: "Selective",
          value: "selective",
        },
        {
          text: "Credit Load",
          value: "CreditLoad",
        },
        {
          text: "Status",
          value: "stat",
        },
        {
          text: "Compulsory",
          value: "Compulsory",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      programs: [],
      activeSession: {},
      programsArr: [],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        name: "",
        courseCode: "2322",
        courseDescription: "Course Discription",
        CreditLoad: "",
      },
      defaultItem: {
        name: "",
        courseCode: "2322",
        courseDescription: "Course Discription",
        CreditLoad: "",
      },
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    department(val) {
      this.getDegrees(val);
    },
  },
  created() {
    this.isEdit = false;
    this.initialize();
  },
  beforeMount() {
    this.fetchCourses();

    const menu = [
      {
        staff_id: "NAU5357",
        menu_name: "Department",
        menu_id: "1234dr3r",
        has_submenu: true,
        sub_menu: [
          {
            submenu_name: "Courses",
            submenu_icon: "sdssd",
            submenu_link: "/profilecourse",
            submenu_id: "898uj-98uj",
            defaultFirst: false,
            programs: ["61ef4514a88631b45cd21f31"],
            departments: [
              "61d7902565e95899bcf1a394",
              "61d7902565e95899bcf1a397",
            ],
            status: 1,
          },
        ],
      },
    ];
    this.fetchSession();
    this.fetchActiveSession();
    // this.fetchProfiledCourses();
  },
  mounted(){
    this.program = this.$router.currentRoute.params.activeSetting.data.data.program._id;
    /****
     * this brough the allowed menus within this scope
     * so filter through to gee the actual departments to represent
     * 
     *  */ 


    this.allowedMenu = this.$router.currentRoute.params.getmyMenus;
console.log(this.allowedMenu)
     /// filter the departments this user is supposed to operate as and use as department array
       this.$router.currentRoute.params.activeDepartments.data.message.forEach(dep => {
        this.allowedMenu.department.some((f) => {
          if(f == dep._id){
        this.departmentsArr.push({
                            text: dep.name,
                            value: {
                                id: dep._id,
                                name: dep.name,
                                code: dep.code
                            }
                        });
                      }
                    })
       });

console.log('lobatab',this.allowedMenu);

       
      //   const nnn = this.departmentsArr.filter((el) => {
      //     return this.allowedMenu.department.some((f) => {
      //       return f == el.value.id ;
      //     });
      //   });

      //  console.log('after filter',nnn);
       
       ////// remember to pass the menu to this function
       this.checkAdminType('menu');

  },
  methods: {
    openProfileType() {
      // console.log('here');
      if (this.profileType) {
        this.validateFetchProfiledCourse();
      } else {
        console.log("hrer");
        this.showImport = true;
        this.shownext = false;
        // this.openImport
      }
    },
    async fetchActiveSession() {
      try {
        const url = process.env.VUE_APP_DEPARTMENT_V1_API_ENDPOINT +'/fetch-active-session'
        const res = await axios.get(
            url
        );
        if (res.data.code === "s200") {
          this.activeSession = {
            value: res.data.message._id,
            text: res.data.message.academicYear,
          };
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getDegrees(dep) {
      if (!this.program)
        return Swal.fire({
          icon: "error",
          title: "Oops..",
          text: "Please make sure you select program",
        });
      try {
        const url = process.env.VUE_APP_DEPARTMENT_V1_API_ENDPOINT+'/department-degrees'
        const res = await axios.post(url,{ departmentId: dep.id, programId: this.program }
        );
        console.log(res);
        if (res.data.code === "s200") {
          this.degrees = res.data.message.resData;
        }else{
          this.degrees = [];
        }
      } catch (error) {
        console.log(error);
      }
    },
    async sendForApproval() {
      this.activity = true
      try {
        if (this.selected.length == 0){
        this.activity = false
          return Swal.fire({
            icon: "error",
            text: "Please select courses to send for approval",
            title: "Oops..",
          });
        }
        console.log("Selected", this.selected);
        const url = process.env.VUE_APP_DEPARTMENT_V1_API_ENDPOINT +'/send-for-approval'
        const res = await axios.post(
            url,
          {
            courseIds: this.selected.map((el) => el.id),
            program: this.program,
          }
        );
        if (res.data.code == "s200") {
          this.activity = false
          this.selected.forEach((course) => {
            this.desserts[this.desserts.indexOf(course)].stat = 2;
            this.desserts[this.desserts.indexOf(course)].status = 2;
            this.desserts[this.desserts.indexOf(course)].disabled = false;
          });
          return Swal.fire({
            icon: "success",
            text: res.data.message,
            title: "Send Profile Course(s) For Approval",
          });
        } else {
          this.activity = false
          return Swal.fire({
            icon: "error",
            text: res.data.message,
            title: "Profiled Course Approval",
          });
        }
        
      } catch (error) {
        this.activity = false
        console.log(error);
      }
    },
    handleImport() {
      console.log(this.importData);
      const data = this.importData.map((course) => {
        return {
          name: course.name,
          course: course.id,
          level: course.level,
          faculty: course.faculty,
          department: course.department,
          semester: course.semester == "First" ? 1 : 2,
          unit: course.CreditLoad,
          degree: course.degree,
          isGsCourse: course.gs,
          compulsory: course.Compulsory=='Compulsory' ? true :false,
          code: course.courseCode,
          profiled_by: this.$logedIndataemail,
        };
      });

      this.postCourse({
        courses: data,
        program: this.program,
      });

      this.showImportedTable = false;
      this.validateFetchProfiledCourse();
    },
    proceedToProfilingType() {
      if (
        this.department < 1 ||
        this.level < 1 ||
        this.semester < 1 ||
        !this.program ||
        !this.degree
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Complete the field Above",
          width: 450,
        });

        return;
      }

      this.checkIfcourseHasBeenProfiled();
    },
    async checkIfcourseHasBeenProfiled() {
      console.log("Checking");
      try {
        const url = process.env.VUE_APP_DEPARTMENT_V1_API_ENDPOINT +'/fetch-profiled-courses'

        const res = await axios.post(
            url,
          {
            department: this.department.id,
            level: this.level.val,
            semester: this.semester.val,
            program: this.program,
            academicSession: this.session,
          }
        );
        console.log("Check res", res);
        if (res.data.code == "s200") {
          console.log("Here though");
          if (res.data.message.length >= 1) {
            this.showImportField = false;
          } else {
            this.showImportField = true;
          }
          this.shownext = true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async fetchProfiledCourses() {
            try {
              const url = process.env.VUE_APP_DEPARTMENT_V1_API_ENDPOINT +'/fetch-profiled-courses?import=import'

                const res = await axios.post(url, {
                    department: this.department.val,
                    semester: this.semester.val,
                    level: toString(this.level.val),
                    program: this.program
                });
                //name, level, facaulty, department, semester, unit, compulsory, code, profiled_by
                console.log('All Profiled Corus', res);
                this.desserts = [];
                if (res.data.code === 's200') {
                    // console.log('FETCCCCH')/
                    res.data.message.forEach(course => {
                        this.desserts.push({
                            name: course.course.name,
                            id: course._id,
                            courseCode: course.code,
                            profiled_code: course ?.profiled_code ? course.profiled_code : 'null',
                            selective: course ?.profiled_code ? 'Yes' : 'No',
                            semester: course.semester == 1 ? 'First' : course.semester == 2 ? 'Second' : '',
                            CreditLoad: course.unit,
                            disabled: course.status >= 2 ? false : true,
                            stat: course.status,
                            gs: course.isGsCourse,
                            status: course.status,
                            Compulsory: course.compulsory ? 'Compulsory' : 'Not Compulsory'
                        });

                    })
                    this.courseHolder.forEach((el) => {
                        const course = this.desserts.find(ell => el.value.name == ell.name) ?? 'flase';
                         console.log('CCCCCCCOURSE', course);
                        if (course == 'flase') {
                            this.courses.push(el);
                        }
                    });

                }
            } catch (error) {
                console.log(error);
            }
        },

    // selectAllToggle(props) {
    //   if (this.selected.length != this.desserts.length - this.disabledCount) {
    //     this.selected = [];
    //     const self = this;
    //     props.items.forEach((item) => {
    //       if (!item.disabled) {
    //         self.selected.push(item);
    //       }
    //     });
    //   } else this.selected = [];
    // },
    async fetchSession() {
      try {
        const url = process.env.VUE_APP_DEPARTMENT_V1_API_ENDPOINT +'/fetch-session'
        const res = await axios.get(url);
        if (res.data.code === "s200") {
          res.data.message.forEach((session) => {
            this.sessions.push({
              value: session.academicYear,
              text: session.academicYear,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async checkAdminType(menu) {
      let staffLevel = "100";
      //Fetch departments
    //   await this.fetchDepartments();

      //fetch programs
    //   await this.fetchProgram();

      //check admin type
      if (staffLevel == "100") {
        this.departments.push(...this.departmentsArr)
        console.log(this.departments);
        return
      }

      //Get route path
      const path = this.$route.path;
      //find the submenu in menu

      let submenuData = null;
      //Filter out current submenu
      menu.forEach((adminmenu) => {
        adminmenu.sub_menu.forEach((submenu) => {
          if (submenu.submenu_link === path) {
            submenuData = submenu;
            return;
          }
        });
      });

      // console.log('SUBBBBBBBB', path, submenuData);

      //Compare programs
      for (let i = 0; i < this.programsArr.length; i++) {
        for (let j = 0; j < submenuData.programs.length; j++) {
          if (this.programsArr[i].value.id == submenuData.programs[j]) {
            this.programs.push(this.programsArr[i]);
          }
        }
      }

      // console.log('DEPARTMENT ARR',this.departmentsArr);
      // Compare push departments
      for (let i = 0; i < this.departmentsArr.length; i++) {
        for (let j = 0; j < submenuData.departments.length; j++) {
          // console.log('REACHED', this.programsArr[i]);
          if (this.departmentsArr[i].value.val == submenuData.departments[j]) {
            this.departments.push(this.departmentsArr[i]);
          }
        }
      }
    },

   
    async editSelectable() {
      this.inputs.forEach((course) => {
        if (!course.CreditLoad || !course.name) {
          return Swal.fire({
            icon: "info",
            text: "Please make sure you fill all field properly",
            title: "Error",
          });
        }
      });

      console.log("INPUTS", this.inputs);
      const data = {
        selectable: this.inputs.map((el) => {
          return {
            course: el.name.id,
            isGs: el.isGs,
            CreditLoad: el.CreditLoad,
            id: el.id,
            name: el.name.name,
          };
        }),
        program: this.program,
      };
      console.log("Payload", data);
      const url = process.env.VUE_APP_DEPARTMENT_V1_API_ENDPOINT +'/edit-profile-course'

      const res = await axios.post(
        url,
        data
      );
      console.log(res);

      if (res.data.code === "s200") {
        this.inputs.forEach((val) => {
          this.desserts[
            this.desserts.indexOf(this.desserts.find((el) => el.id === val.id))
          ].name = val.name.name;
          this.desserts[
            this.desserts.indexOf(this.desserts.find((el) => el.id === val.id))
          ].CreditLoad = val.CreditLoad;
          this.desserts[
            this.desserts.indexOf(this.desserts.find((el) => el.id === val.id))
          ].isGs = val.isGs;
        });
        this.isEdit = false;
        this.inputs = [
          {
            name: "",
            CreditLoad: "",
            isGs: false,
          },
        ];

        Swal.fire({
          icon: "success",
          text: "Edited successfully",
          title: "Successful",
        });
      } else {
        Swal.fire({ icon: "error", text: res.data.message, title: "Oops.." });
      }
    },
    showForm() {
      if (this.selective) {
        this.showSelective = true;
      } else {
        this.showSingleProfile = true;
      }
      this.showRadio = false;
    },
    Back() {
      this.details = false;
      this.department = null;
      this.level = null;
      this.semester = null;
      this.unit = "";
      this.compulsory = false;
      this.selective = false;
      this.isGs = false;
      this.course = null;
    },
    radioBack() {
      this.showRadio = true;
      this.showSelective = false;
      this.showSingleProfile = false;
      this.inputs = [
        {
          name: "",
          courseDescription: "Course Discription",
          CreditLoad: "",
          isGs: false,
        },
      ];
    },
    initialize() {
      this.desserts = [];
    },
    closeSelective() {
      this.dialog = false;
      this.selective = false;
    },
    removeSelective(index) {
      this.inputs.splice(index, 1);
    },
    add() {
      if (this.inputs.length < 9) {
        this.inputs.push({
          name: "",
          grade: "",
          courseCode: "",
          CreditLoad: "",
          // Compulsory: ''
        });
      } else {
        Swal.fire({
          icon: "error",
          // title: 'Maximum Field',
          text: "Maximum Field Reached",
          width: 400,
        });
      }
    },
    async fetchCourses() {
        const url = process.env.VUE_APP_DEPARTMENT_V1_API_ENDPOINT +'/get-courses'
      try {
        const res = await axios.get(url);
        console.log("Course", res);
        if (res.data.code == "s200") {
          res.data.message.forEach((course) => {
            this.courseHolder.push({
              text: `${course.name+' ('+course.course_code+' )'}`,
              value: {
                code: course.course_code,
                name: course.name,
                id: course._id,
              },
            });
            this.courseSelect.push(course.name);
          });
          // this.courseHolder.push(...this.courses);
          // console.log('Profiled Course', res);
        }
      } catch (error) {
        console.log(error);
      }
    },

    addSelective() {
      if (this.value.length === 0) {
        this.selective = false;
        return Swal.fire({
          icon: "info",
          title: "Oops..",
          text: "Choose course or Cancel",
        });
      }
      const value = this.value.join("/");
      this.courses.push(value);
      this.dialog = false;
      this.selective = false;
    },

    show() {
      this.dialog = true;
    },

    showDelete(item) {
      this.item = item;
      this.dialogDelete = true;
    },

    deleteItem(item) {
      console.log(item);
      this.item = item;
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      console.log(this.item);
      if(this.item.disabled == false) {
if(this.reasontoD ==''){
  return Swal.fire({
            icon: "error",
            text: "We will like to know the reason why you want to delete this profiled course.",
            title: "Delete Profiled Course",
          });
}
      }
      try {
        const url = process.env.VUE_APP_DEPARTMENT_V1_API_ENDPOINT +'/delete-profiled-course'

        const res = await axios.post(
            url,
          {
            reason:this.reasontoD,
            code: this.item.courseCode,
            department: this.department.id,
            semester: this.semester.val,
            level: this.level.val,
            program: this.program,
          }, { withCredentials: true }
        );
        if (res.data.code === "s200") {
          if(this.item.disabled == true) {
          this.desserts.splice(this.editedIndex, 1);
          }
          const course = this.courseHolder.find(
            (el) => el.value.name == this.item.name
          );
          this.courses.push(course);
          this.closeDelete();

          return Swal.fire({
            icon: "success",
            text: res.data.message,
            title: "Successful",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    close() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save2() {
      if (
        this.inputs.name == "" ||
        this.inputs.Compulsory == "" ||
        this.inputs.CreditLoad == ""
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Incomplete Details`,
          width: 450,
        });
      } else {
        if (this.editedIndex > -1) {
          Object.assign(this.desserts[this.editedIndex], this.inputs);
        } else {
          let arr = [];
          const profiled_code = Math.floor(1000000 + Math.random() * 9000);
          this.inputs.forEach((input) => {
            arr.push({
              name: input.name.name,
              course: input.name.id,
              level: this.level.val,
            //   faculty: this.department.fac._id,
              department: this.department,
              semester: this.semester.val,
              degree: this.degree,
              unit: input.CreditLoad,
              profiled_code: profiled_code,
              compulsory: input.compulsory,
              code: input.name.code,
              isGsCourse: input.isGs,
              profiled_by:this.$logedIndataemail,
            });
          });
console.log('chill',arr);
          this.postCourse({
            courses: arr,
            profiled_code,
            program: this.program,
          });
          arr = [];

          this.inputs.forEach((input) => {
            this.desserts.push({
              Compulsory: input.Compulsory ? "Compulsory" : "Not Compulsory",
              CreditLoad: input.CreditLoad,
              courseCode: input.name.code,
              name: input.name.name,
            });
          });
          // console.log(this.desserts);
          this.selective = false;
          this.inputs = [
            {
              name: "",
              courseCode: "2322",
              courseDescription: "Course Discription",
              CreditLoad: "",
              Compulsory: false,
            },
          ];
        }
        this.dialog = false;
      }
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    validateFetchProfiledCourse() {
      this.desserts = [];
      this.fetchProfiledCourses();
      this.details = true;
      this.shownext = false;
      this.profileType = true;
    },

    async fetchProfiledCourses() {
      try {
        const url = process.env.VUE_APP_DEPARTMENT_V1_API_ENDPOINT +'/fetch-profiled-courses'

        const res = await axios.post(
            url,
          {
            department: this.department.id,
            semester: this.semester.val,
            level: this.level.val,
            program: this.program,
          }
        );
        //name, level, facaulty, department, semester, unit, compulsory, code, profiled_by
        console.log("All Profiled Corus", res);
        this.desserts = [];
        if (res.data.code === "s200") {
          // console.log('FETCCCCH')/
          res.data.message.forEach((course) => {
            this.desserts.push({
              name: course.course?.name,
              id: course._id,
              courseCode: course.code,
              profiled_code: course?.profiled_code
                ? course.profiled_code
                : "null",
              selective: course?.profiled_code ? "Yes" : "No",
              semester:
                course.semester == 1
                  ? "First"
                  : course.semester == 2
                  ? "Second"
                  : "",
              CreditLoad: course.unit,
              disabled: course.status >= 2 ? false : true,
              stat: course.status,
              gs: course.isGsCourse,
              status: course.status,
              Compulsory: course.compulsory ? "Compulsory" : "Not Compulsory",
            });
          });
          console.log('the course holder',this.courseHolder);
          console.log('this desert',this.desserts);
          this.courseHolder.forEach((el) => {
            const course =
              this.desserts.find((ell) => el.value.name == ell.name) ?? "flase";
            // console.log('CCCCCCCOURSE', course);
            if (course == "flase") {
              this.courses.push(el);
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async postCourse(data) {
      console.log('PROFILE DATA', data)
      const url = process.env.VUE_APP_DEPARTMENT_V1_API_ENDPOINT +'/profile-course'

      const res = await axios.post(
        url,
        data
      );
      console.log(res);
      if (res.data.code === "s200") {
        this.unit = null;
        this.course = null;
        this.compulsory = false;
        this.isGs = false;
        this.selective = false;
        this.desserts = [];
        this.fetchProfiledCourses();
        return Swal.fire({
          icon: "success",
          title: "Successful",
          text: res.data.message,
        });
      } else {
        return Swal.fire({
          icon: "error",
          title: "Oops..",
          text: res.data.message,
        });
      }
    },

    editSelective(item) {
      this.isEdit = true;
      //find all related courses
      const relatedCourse = this.desserts.filter(
        (course) => course.profiled_code === item.profiled_code
      );
      console.log("here", relatedCourse);
      //show selectable view
      this.showSelective = true;
      this.showRadio = false;
      //populate selectable array
      this.inputs = [];
      relatedCourse.forEach((course) => {
        const courseItem = this.courseHolder.find(
          (el) => el.name === course?.name
        );
        this.inputs.push({
          name: courseItem,
          CreditLoad: course.CreditLoad,
          isGs: course.gs,
          id: course.id,
          courseName: course?.name,
        });
      });

      // console.log(item);
    },
    showEdit(item) {
      if (item.selective === "Yes") {
        return this.editSelective(item);
      }
      this.item = item;
      this.dialogEdit = true;
      const course = this.desserts.find((course) => course.name == item.name);
      console.log("Course", course);
      this.course_edit = course.value;

      this.unit_edit = Number(item.CreditLoad);
      this.isGs_edit = item.gs;
      this.compulsory_edit = item.Compulsory == "Compulsory" ? true : false;
    },

    async editCourse() {
      try {
     console.log(this.item);   
        if (!this.unit_edit)
          return Swal.fire({
            icon: "error",
            text: "Credit unit cannot be empty",
            title: "Oops..",
          });
        const data = {
          unit: this.unit_edit,
          isGsCourse: this.isGs_edit,
          id: this.item.id,
          program: this.program,
        };
        // console.log(data);
        const url = process.env.VUE_APP_DEPARTMENT_V1_API_ENDPOINT +'/edit-profile-course'

        const res = await axios.post(
            url,
          data, { withCredentials: true }
        );
        if (res.data.code === "s200") {
          const index = this.desserts.indexOf(this.item);
          this.desserts[index].CreditLoad = this.unit_edit;
          this.dialogEdit = false;
          this.courses = [];
          this.fetchProfiledCourses();
          Swal.fire({
            icon: "success",
            text: res.data.message,
            title: "Success",
          });
        } else {
          Swal.fire({
            icon: "error",
            text: res.data.message,
            title: "Error",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async save() {

      if (
        this.course.name == "" ||
        !this.department ||
        this.semester.val == "" ||
        this.level.val == "" || !this.unit
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Incomplete Details`,
          width: 450,
        });
      } else {
        try {
          const data = {
            name: this.course.name,
            course: this.course.id,
            level: this.level.val,
            // faculty: this.department.fac._id,
            department: this.department.id,
            semester: this.semester.val,
            unit: this.unit,
            degree: this.degree,
            isGsCourse: this.isGs,
            compulsory: this.compulsory,
            code: this.course.code,
            profiled_by: this.$logedIndataemail,
          };

          const courseIndex = this.courses.indexOf(
            this.courses.find((el) => this.course.name === el.value.name)
          );

          this.courses.splice(courseIndex, 1);
          // console.log('DATA', {course: [data]});

          this.postCourse({
            courses: [data],
            program: this.program,
          });
        } catch (error) {
          console.log(error);
        }
      }
      this.close();
    },
    async handleFetchProfileCourse(){
            try {
                this.importData = [];
                if(!this.session) return Swal.fire({icon: 'error', text: 'please select session', title: 'Oops..'})
var url = process.env.VUE_APP_DEPARTMENT_V1_API_ENDPOINT +'/fetch-profiled-courses?import=import';
                const res = await axios.post(url, {department: this.department.id, level: this.level.val, semester: this.semester.val, program: this.program, academicSession: this.session});
                if(res.data.code === 's200'){
                    res.data.message.forEach(course => {
                        this.importData.push({
                            name: course.course.name,
                            id: course.course._id,
                            level: course.level,
                            courseCode: course.code,
                            profiled_code: course?.profiled_code ? course.profiled_code : 'null',
                            selective: course?.profiled_code ? 'Yes' : 'No',
                            semester: course.semester == 1 ? 'First' : course.semester == 2 ? 'Second' : '',
                            CreditLoad: course.unit,
                            isSelectable: course.status == 3,
                            status: course.status,
                            gs: course.isGsCourse,
                            department: course.department,
                            faculty: course.department.faculty,
                            Compulsory: course.compulsory ? 'Compulsory' : 'Not Compulsory'
                        });
                    })
                    this.session  = '';
                    this.showImportedTable = true;
                    this.showImport = false;
                }
            } catch (error) {
                console.log(error)
            }
        },
  },
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");

.creditLoad {
  
}

.profileCourse {
  width: 100%;
  height: auto;
  background: #ffffff;
  padding: 20px 0;

  .form-div {
    // border: 1px solid #4F4F4F;
    margin-left: 15rem;
    margin-top: 20px;
    width: 60%;
    padding: 20px;
    height: auto;
    background: #ffffff;
  }

  @media (max-width: 1000px) {
    .form-div {
      // border: 1px solid #4F4F4F;
      margin-left: 0;
      margin-top: 20px;
      width: 100%;
      padding: 20px;
      height: auto;
      background: #ffffff;
    }
  }

  .button-sub {
    padding: 15px;
    width: 20%;
    height: auto;
    background: #006794;
    border-radius: 3px;

    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #ffffff;
  }

  .button-sub2 {
    padding: 15px;
    height: auto;
    background: #006794;
    border-radius: 3px;

    font-size: 20px;
    line-height: 16px;
    color: #ffffff;
  }

  @media (max-width: 1000px) {
    .button-sub2 {
      padding: 15px;
      width: 100%;
      height: auto;
      background: #006794;
      border-radius: 3px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 16px;
      color: #ffffff;
    }
  }

  .button-sub3 {
    padding: 15px;
    width: 40%;
    height: auto;
    background: #006794;
    border-radius: 3px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #ffffff;
  }

  .table-selected {
    padding: 1rem;
    border: 1px solid #f5f5f5;
    margin: 1rem;
    border-radius: 10px;
  }

  .table-selected2 {
    padding: 1rem;
    border: 1px solid #f5f5f5;
    margin-left: 0;
  }

  .creditLoad {
    margin: 0;
    padding: 15px;
    width: 80%;
    height: 46px;
    background: #ffffff;
    border: 0.5px solid #b0afaf;
    box-sizing: border-box;
    border-radius: 4px;
  }
}

.title-h5 {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #4f4f4f;
  // padding: 10px 20px;
}
</style>
