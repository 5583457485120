<template>
  <div class="home">
    <div class="row">
      <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
        <span style="color:#777; font-size:18px;padding:5px;">Department</span>/<span style="padding:5px; color:#555" @click="closeSettings"> Course registration setup </span> <span v-if="department">/ Settings for <strong>{{ department.name }} Department</strong></span>
      </div>
    </div>
  
    <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
      <v-row style="margin:0 !important; padding:0 !important">
    <v-col cols="12" style="">
      <div class=" mb-8">
        <v-card v-if="showform" style="margin: 0 auto" class="pa-12" width="700">
          <div>
            <v-autocomplete dense :items="degrees" v-model="degree" outlined label="Select Degree"></v-autocomplete>
            <v-autocomplete dense :items="departments" v-model="department" outlined label="Select Department"></v-autocomplete>
            <v-btn color="#c0e7fe" block @click="initialize" class="ma-auto pa-3">Next</v-btn>
            <!-- <v-btn color="primary" class="pa-3" block @click="shownext=true">Next</v-btn> -->
          </div>
  
          <v-dialog width="500" v-model="shownext" persistent>
            <v-card>
              <v-card-title style="color:#666; font-size:18px">
                Select Setup Type
                <v-spacer></v-spacer>
                <v-icon @click="shownext=false">mdi-close-circle</v-icon>
  
              </v-card-title>
              <v-divider style="margin-top:0px"></v-divider>
  
              <v-card-text>
                <v-radio-group v-model="setupType">
                  <v-radio label="New Setup" :value="true"></v-radio>
                  <v-radio label="Import Setup" :value="false">
                  </v-radio>
                </v-radio-group>
              </v-card-text>
              <v-card-actions>
  
                <v-spacer></v-spacer>
  
                <v-btn color="#c0e7fe" class="col-md-5 ma-auto" @click="proceed">Proceed</v-btn>
                <!-- <button class="next_button" @click="save">
                                              Add
                                          </button> -->
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="showimport" persistent width="500">
            <v-card>
              <v-card-title style="color:#666; font-size:18px">
                Select Session to proceed
                <v-spacer></v-spacer>
                <v-icon @click="showimport=false">mdi-close-circle</v-icon>
  
              </v-card-title>
              <v-divider style="margin-top:0px"></v-divider>
  
              <v-card-text>
                <strong class="mb-3 d-block">Select Session</strong>
                <v-autocomplete v-model="session" :items="sessions" dense outlined label="Select Session"></v-autocomplete>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#c0e7fe" class="col-md-5 ma-auto" @click="importSession">Import</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
        <div v-else class="pa-0">
          <v-card class="pt-2"> 
          <!-- <v-btn @click="closeSettings">Back</v-btn> -->
  <v-alert type="info" class="ma-4"> 
    Fill in the maximum and minimum credit unit for {{ department.name }} department. Click on Save Settings to save the settings. Once you save, you will not be able to change the settings again from here. Saved settings can only be edited by the MICTU
  </v-alert>
          <v-row>
            <div class="col-sm-12 col-md-6 col-md-6">
              <div class="pa-5">
                <h5>First semester</h5>
                <hr>
                <div>
                  <div>
  
                    <v-row v-for="(credit, i) in creditLoadsFirstSemester" :key="i" class="mt-2">
                      <div class="col-md-12" style="margin-top:-20px">
                        <strong class="">{{credit.level}}00 Level</strong>
                      </div>
                      <div class="col-md-6" style="margin-top:-25px">
                        Minimum credit unit
                        <v-text-field v-model="credit.min" dense outlined type="number" max="2"></v-text-field>
                      </div>
                      <!-- <v-col cols="3"><strong>Min</strong>
                        <v-text-field v-model="credit.min" dense outlined type="number"></v-text-field>
                      </v-col> -->
                      <div class="col-md-6" style="margin-top:-25px">
                        Maximum credit unit
                        <v-text-field v-model="credit.max" dense outlined type="number" max="2"></v-text-field>
                      </div>
                    </v-row>
                    <v-row>
                      <div class="col-md-12" style="margin-top:-20px"><strong class="">Extra Year</strong></div>
                      <div class="col-md-6" style="margin-top:-25px">Minimum credit unit
                        <v-text-field v-model="extraYearFirstSemester.min"  dense outlined type="number"></v-text-field>
                      </div>
                      <div class="col-md-6" style="margin-top:-25px">Maximum credit unit
                        <v-text-field v-model="extraYearFirstSemester.max"  dense outlined type="number"></v-text-field>
                      </div>
                    </v-row>
                  </div>
  
                </div>
                <div>
                  <v-btn  @click="submit('1')" color="primary">Save Settings</v-btn>
                </div>
                <!-- <div v-else>
                  <v-btn color="primary" disabled>Awaiting approval</v-btn>
                </div> -->
              </div>
  
            </div>
  
            <div class="col-sm-12 col-md-6 col-md-6">
              <div class="pa-5" style="border-left: 1px solid gray">
                <h5>Second semester</h5>
                <hr>
  
                <div>
  
                  <v-row v-for="(credit, i) in creditLoadsSecondSemester" :key="i" class="mt-2">
                    <div class="col-md-12" style="margin-top:-20px"><strong class="">{{credit.level}}00 Level</strong></div>
                    <div class="col-md-6" style="margin-top:-25px">Minimum credit unit
                      <v-text-field v-model="credit.min"  dense outlined type="number"></v-text-field>
                    </div>
                    <div class="col-md-6" style="margin-top:-25px">Maximum credit unit
                      <v-text-field v-model="credit.max"  dense outlined type="number"></v-text-field>
                    </div>
                  </v-row>
                  <v-row>
                    <div class="col-md-12" style="margin-top:-20px"><strong class="">Extra Year</strong></div>
                    <div class="col-md-6" style="margin-top:-25px">Minimum credit unit
                      <v-text-field v-model="extraYearSecondSemester.min"  dense outlined type="number"></v-text-field>
                    </div>
                    <div class="col-md-6" style="margin-top:-25px">Maximum credit unit
                      <v-text-field v-model="extraYearSecondSemester.max"  dense outlined type="number"></v-text-field>
                    </div>
                  </v-row>
                </div>
                <div >
                  <v-btn  @click="submit('2')" color="primary">Save Settings</v-btn>
                </div>
                <!-- <div v-else>
                  <v-btn color="primary" disabled>Awaiting approval</v-btn>
                </div> -->
              </div>
            </div>
  
          </v-row>
        </v-card>
        </div>
      </div>
      </v-col>
      </v-row>

    </div>
  </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  import { degree, allSession, allDepartments,menusAsigned,fetchactiveSets } from "@Appmart/utility";

  export default {
    data() {
      return {
        tab: null,
        showFirst: true,
        disableBtn1: false,
        disableBtn2: false,
        showSecond: false,
        noOfYears:'',
        shownext: false,
        departments: [],
        department: '',
        degrees: [],
        program:this.$activeSetting.data.program._id,
        degree: '',
        sessions: ['2021_2022'],
        session: '',
        showimport: false,
        showform: true,
        setupType: true,
        creditLoadsSecondSemester: [],
        creditLoadsFirstSemester: [],
        extraYearFirstSemester: {
          min: '',
          max: '',
          semester: '1'
        },
        extraYearSecondSemester: {
          min: '',
          max: '',
          semester: '2'
        },
      }
    },
    async beforeMount() {
      this.fetchallDegrees();
      this.fetchSession();
      this.fetchDepartments();
      // this.getactiveSettings();
    },


mounted(){
this.allowedMenu = this.$router.currentRoute.params.getmyMenus;

},

    methods: {
    
  //     //// get the active setting so we can retive the active program to run
  //     async getactiveSettings(){
  //       const activeSetting = await fetchactiveSets().then(activeSet => {
  //   return activeSet;
  // }).catch(e => {
  //   console.log(e);
  //   alert('Something went wrong')
  //   next(false)
  // })
  //     },


      async submit(semester) {
        try {
          const settings = [];
  
          if (semester == '1') {
            const firstSemester = this.creditLoadsFirstSemester.map(el => {
              const vals = Object.values(el);
  
              if (vals.includes('') || vals.includes(' ')) {
                return Swal.fire({
                  icon: 'error',
                  text: 'Please make sure you fill out everything',
                  title: 'Oops..'
                });
              }
  
              return {
                min: el.min,
                max: el.max,
                level: el.level,
                semester: el.semester,
                exMax: this.extraYearFirstSemester.max,
                exMin: this.extraYearFirstSemester.min,
              }
            })
  
            settings.push(...firstSemester)
          }
  
          if (semester == '2') {
            const secondSemester = this.creditLoadsSecondSemester.map(el => {
              const vals = Object.values(el);
  
              if (vals.includes('') || vals.includes(' ')) {
                return Swal.fire({
                  icon: 'error',
                  text: 'Please make sure you fill out everything',
                  title: 'Oops..'
                });
              }
  
              return {
                min: el.min,
                max: el.max,
                level: el.level,
                semester: el.semester,
                exMax: this.extraYearFirstSemester.max,
                exMin: this.extraYearFirstSemester.min,
              }
            })
  
            settings.push(...secondSemester)
          }
  
          const data = {
            departmentId: this.department,
            createdBy: 'Skolarsuite',
            degree: this.degree,
            settings
          }
  var url = this.$appUrl+'/course-settings';
          const res = await axios.post(url, data);
  
          if (res.data.code == 's200') {
            // this.disableBtn = true;
  
            if (semester == '1') {
              this.disableBtn1 = true;
            }
  
            if (semester == '2') {
              this.disableBtn2 = true;
            }
            Swal.fire({
              icon: 'suceess',
              text: res.data.message,
              title: 'Successful'
            });
          } else {
            Swal.fire({
              icon: 'error',
              text: res.data.message,
              title: 'OOps..'
            });
          }
  
          // console.log('DASSTS', settings, data);
        } catch (error) {
          console.log(error);
        }
  
      },




      async fetchNumberOfYear() {
        try {
          var url = this.$appUrl+'/fetch-no-year'

          const res = await axios.post(url, {
            departmentId: this.department,
            degreeId: this.degree,
            programId: this.program
          });
          console.log(res);
  
          if (res.data.code == 's200') {
            const continue2 = this.creditLoadsSecondSemester.length ==0 ? true : false;
            const continue1 = this.creditLoadsFirstSemester.length == 0 ? true : false;
            
            this.noOfYears = res.data.message

            for (let i = 0; i < res.data.message; i++) {
              if(continue1){
                this.creditLoadsFirstSemester.push({
                level: i + 1,
                max: '',
                min: '',
                semester: '1'
              })
              }
  
              if(continue2){
                this.creditLoadsSecondSemester.push({
                  level: i + 1,
                  max: '',
                  min: '',
                  semester: '2'
                })
              }
            }
  
            return false;
          }
  
          return res;
        } catch (error) {
          console.log(error);
        }
      },
      closeSettings() {
        this.showform = true;
        this.disableBtn1 = false;
        this.disableBtn1 = false;
        this.degree = '';
        this.department = '';
      },

      async fetchSession() {
        try {
          const activeSetting = await allSession().then(sessions => {
    return sessions;
  }).catch(e => {
    console.log(e);
    alert('Something went wrong')
    next(false)
  })
  
  if(activeSetting.data.code == 's200'){
  activeSetting.data.message.forEach(session => {
  this.sessions.push({
                value: session.academicYear,
                text: session.academicYear,
              })
            })
            }
      
        } catch (error) {
          console.log(error);
        }
      },
      async checkDepartmentSettings() {
        try {
          var url = this.$appUrl+'/fetch-department-course-settings'

          const res = await axios.get(url);
  
          console.log(res);
        } catch (error) {
          console.log(error);
        }
      },

      async fetchDepartments() {
        try {

                    const alldepartments = await allDepartments()
          .then((departments) => {
            return departments;
          })
          .catch((e) => {
            console.log(e);
          });


        ///// fiter the departments that are needed.

        alldepartments.data.message.forEach(dep => {
        this.allowedMenu.department.some((f) => {
          if(f == dep._id){
        this.departments.push({
                            text: dep.name,
                            value: {
                                id: dep._id,
                                name: dep.name,
                                code: dep.code
                            }
                        });
                      }
                    })
       });



      }catch(e){
console.log(e);
      }

    },


      async importSession() {
        if (!this.session) {
          return Swal.fire({
            icon: 'error',
            text: 'Please Select Session to Proceed',
            title: 'Oops..`'
          })
        }
  
        this.showform = false;
        console.log('imported');
        var url = this.$appUrl+'/fetch-course-settings'
        const res = await axios.post(url, {
          degree: this.degree,
          departmentId: this.department,
          session: this.session
        });
  
        console.log('Import Response', res);
        if (res.data.code == 's200') {
          if (res.data.message.length == 0) {
            this.showimport = false;
            this.session = '';
            Swal.fire({
              icon: 'info',
              text: 'There is no previous settings for' + this.session,
              title: 'Oops..'
            })
          } else {
            this.populateCourseSettings(res, false)
            this.showimport = false;
            this.session = '';
            Swal.fire({
              icon: 'success',
              text: `${this.session} Course Credit Load Settings Was Successful`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
            });
          }
        } else {
          return Swal.fire({
            icon: 'error',
            text: res.data.message,
            title: 'Oops..'
          })
        }
      },
  

      /////// fetch degree based on the programm
      async fetchallDegrees() {
            try {
        const programDegree = await degree()
          .then((activeSet) => {
            return activeSet;
          })
          .catch((e) => {
            console.log(e);
          });

        // if (degree.data.code == "00") {
          programDegree.data.message.forEach(el => {
                this.degrees.push({
                  text: el.name,
                  value: {
                    id: el._id,
                    name: el.name
                  }
                })
              });
        // }
      } catch (error) {
        console.log(error);
      }
       
    
        },
  
      async initialize() {
        if(this.department =='' || !this.department){
          return Swal.fire({
            icon: 'error',
            text: 'Select Department to continue',
            title: 'Course Registration Settings'
          })
        }

        if(this.degree =='' || !this.degree){
          return Swal.fire({
            icon: 'error',
            text: 'Select Degree',
            title: 'Course Registration Settings'
          })
        }

        if(this.program =='' || !this.program){
          return Swal.fire({
            icon: 'error',
            text: 'Program is required',
            title: 'Course Registration Settings'
          })

}

        try {
          console.log(this.degree);
          var url = this.$appUrl+'/fetch-course-setting';
          const res = await axios.post(url, {
            departmentId: this.department,
            degree: this.degree
          });
  
          console.log('Department course settings', res)
  
          if (res.data.code == 's200') {
            this.disableBtn = false;
            this.creditLoadsFirstSemester = [];
            this.creditLoadsSecondSemester = [];
            this.extraYearFirstSemester = {};
            this.extraYearSecondSemester = {};
            if (res.data.message.length == 0) {
              console.log('here1')
              this.next();
            } else {
              this.populateCourseSettings(res, true);
            }
          }
  
        } catch (error) {
          console.log(error);
        }
      },
      populateCourseSettings(res) {
        const firstSemester = res.data.message.filter(el => el.semester == 1)
        const secondSemester = res.data.message.filter(el => el.semester == 2)
  
        if (firstSemester.length !== 0) {
          this.creditLoadsFirstSemester = firstSemester.map(el => {
            return {
              min: el.minCredit,
              max: el.maxCredit,
              level: el.level,
              semester: el.semester
            }
          })
  console.log( this.creditLoadsFirstSemester);
          this.extraYearFirstSemester = {
            min: this.creditLoadsFirstSemester[0].min,
            max: this.creditLoadsFirstSemester[0].max,
            semester: this.creditLoadsFirstSemester[0].semester
          }
  
        this.disableBtn1 = true;
  
        }else{
          this.fetchNumberOfYear()
        }
  
        if (secondSemester.length !== 0) {
          this.creditLoadsSecondSemester = secondSemester.map(el => {
            
            return {
              min: el.minCredit,
              max: el.maxCredit,
              level: el.level,
              semester: el.semester
            }
          })
  

          this.extraYearSecondSemester = {
            min: this.creditLoadsSecondSemester[0].min,
            max: this.creditLoadsSecondSemester[0].max,
            semester: this.creditLoadsSecondSemester[0].semester
          }
          this.disableBtn2 =true;
        }else{
          this.fetchNumberOfYear()
        }
  
        this.showform = false;
        
      },
      proceed() {
        if (this.setupType) {
          this.showform = false;
          this.shownext = false;
        } else {
          this.showimport = true;
          this.shownext = false;
          // this.showform = false;
        }
        console.log('here');
      },
      async next() {
        if (!this.degree || !this.department) {
          return Swal.fire({
            icon: 'error',
            text: 'Please select degree and department to proceed',
            title: 'Oops..'
          })
        } else {
          const checkNoOfYear = await this.fetchNumberOfYear();
  
          if (checkNoOfYear) {
            return Swal.fire({
              icon: 'error',
              text: checkNoOfYear.data.message,
              title: 'Oops..'
            });
          }
          this.shownext = true;
  
        }
  
      }
    }
  
  }
  </script>
  
  <style>
  
  </style>
  