import Vue from 'vue'
import VueRouter from 'vue-router'
import { fetchactiveSets,allDepartments, getCookie,menusAsigned,parseJwt} from '@Appmart/utility';

////////// started here
import CreateCourse from "../../src/components/courses/hostedCourse.vue";
import profileCourse from "../../src/components/courses/profileCourse.vue";
import approveProfiledCourse from "../../src/components/courses/approveProfiledCourse";
import courseRegSetup from "../../src/components/courses/regSettings.vue"
import assignCourse from "../../src/components/courses/assignCourse";
import approveProfileapprove from "../../src/components/courses/approveProfileEdit.vue";
import courseRegistrationReport from "../../src/components/courses/courseRegReport.vue";
Vue.use(VueRouter)

const routes = [
  {
    path: "/department/host-course",
    name: "CreateCourse",
    beforeEnter: async (to, from, next) => {
 // Inside of app1, you can import something from an "api" utility module
 const activeSetting = await fetchactiveSets().then(activeSet => {
  return activeSet;
}).catch(e => {
  console.log(e);
  alert('Something went wrong')
  next(false)
})

const activeDepartments = await allDepartments().then(activeSet => {
  return activeSet;
}).catch(e => {
  console.log(e);
  alert('Something went wrong')
  next(false)
})

console.log(activeDepartments)
if(activeDepartments.data.code == '00'){
  to.params.activeDepartments = activeDepartments;
  next({ params: to.params });
  }else{
    next(false)
  }


    },
    component: CreateCourse,
    meta:{
      requiresAuth:true
    }
  },
  {
    path: "/department/apprveEdit",
    name: "approveProfileEdit",
    // beforeEnter: async (to, from, next) => {
    //   // Inside of app1, you can import something from an "api" utility module
    //  const activeDepartments = await allDepartments().then(activeSet => {
    //    return activeSet;
    //  }).catch(e => {
    //    console.log(e);
    //    alert('Something went wrong')
    //    next(false)
    //  })
    //  console.log(activeDepartments)
    //  if(activeDepartments.data.code == '00'){
    //    to.params.activeDepartments = activeDepartments;
    //    next({ params: to.params });
    //    }else{
    //      next(false)
    //    }
     
    //      },
    component: approveProfileapprove,
    meta:{
      requiresAuth:true
    }
  },
  {
    path: "/department/assigncourse",
    name: "assignCourse",
    // beforeEnter: async (to, from, next) => {
    //   // Inside of app1, you can import something from an "api" utility module
    //  const activeDepartments = await allDepartments().then(activeSet => {
    //    return activeSet;
    //  }).catch(e => {
    //    console.log(e);
    //    alert('Something went wrong')
    //    next(false)
    //  })
    //  console.log(activeDepartments)
    //  if(activeDepartments.data.code == '00'){
    //    to.params.activeDepartments = activeDepartments;
    //    next({ params: to.params });
    //    }else{
    //      next(false)
    //    }
     
    //      },
    component: assignCourse,
    meta:{
      requiresAuth:true
    }
  },
  {
    path: "/department/regSetting",
    name: "regSetup",
    // beforeEnter: async (to, from, next) => {
    //   // Inside of app1, you can import something from an "api" utility module
    //  const activeDepartments = await allDepartments().then(activeSet => {
    //    return activeSet;
    //  }).catch(e => {
    //    console.log(e);
    //    alert('Something went wrong')
    //    next(false)
    //  })
    //  console.log(activeDepartments)
    //  if(activeDepartments.data.code == '00'){
    //    to.params.activeDepartments = activeDepartments;
    //    next({ params: to.params });
    //    }else{
    //      next(false)
    //    }
     
    //      },
    component: courseRegSetup,
    meta:{
      requiresAuth:true
    }
  },
  {
    path: "/department/profile-course",
    name: "profileCourse",
    beforeEnter: async (to, from, next) => {
 // Inside of app1, you can import something from an "api" utility module


const activeDepartments = await allDepartments().then(activeSet => {
  return activeSet;
}).catch(e => {
  console.log(e);
  alert('Something went wrong')
  next(false)
})
console.log(activeDepartments)
if(activeDepartments.data.code == '00'){
  to.params.activeDepartments = activeDepartments;
  next({ params: to.params });
  }else{
    next(false)
  }


    },
    component: profileCourse,
    meta:{
      requiresAuth:true
    }
  },
  
  {
    path: "/department/approveprofiledcourses",
    name: "approveProfiledCourse",
    beforeEnter: async (to, from, next) => {
 // Inside of app1, you can import something from an "api" utility module
const activeDepartments = await allDepartments().then(activeSet => {
  return activeSet;
}).catch(e => {
  console.log(e);
  alert('Something went wrong')
  next(false)
})
console.log(activeDepartments)
if(activeDepartments.data.code == '00'){
  to.params.activeDepartments = activeDepartments;
  next({ params: to.params });
  }else{
    next(false)
  }


    },
    component: approveProfiledCourse,
    meta:{
      requiresAuth:true
    }
  },
  {
    path: "/department/cregreport",
    name: "courseRegReport",
    // beforeEnter: async (to, from, next) => {
    //   // Inside of app1, you can import something from an "api" utility module
    //  const activeDepartments = await allDepartments().then(activeSet => {
    //    return activeSet;
    //  }).catch(e => {
    //    console.log(e);
    //    alert('Something went wrong')
    //    next(false)
    //  })
    //  console.log(activeDepartments)
    //  if(activeDepartments.data.code == '00'){
    //    to.params.activeDepartments = activeDepartments;
    //    next({ params: to.params });
    //    }else{
    //      next(false)
    //    }
     
    //      },
    component:courseRegistrationReport ,
    meta:{
      requiresAuth:true
    }
  }
 
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})


router.beforeEach(async(to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
  console.log(to.fullPath);  
  /**
   * before we can enter any route on this module, we will have to get the active session as well as get the menus for this operation
   */
   let name = getCookie("-X_Zka");
   const tokendecoded = parseJwt(name);

   const activeSetting = await fetchactiveSets().then(activeSet => {
    return activeSet;
  }).catch(e => {
    console.log(e);
    alert('Something went wrong')
    next(false)
  })

  const getmyMenus = await menusAsigned('userExec',activeSetting.data.data.program._id,tokendecoded.staffid).then(activeSet => {
    return activeSet;
  }).catch(e => {
    console.log(e);
    alert('Something went wrong')
    next(false)
  })

  console.log('found',getmyMenus.data.message.deptAssigned);

  const checkifMenuIsBuilt = getmyMenus.data.message.deptAssigned.find(menuId =>menuId.menuLink ==to.fullPath);
//const checkifMenuIsBuilt = true;
  console.log('found_menu',checkifMenuIsBuilt);
if(checkifMenuIsBuilt){
  to.params.activeSetting = activeSetting;
  to.params.getmyMenus = checkifMenuIsBuilt;
  next({ params: to.params })
}else{
    window.location.href =`${window.location.origin}/#/auth`
  return
}
  }
//   if(to.matched.some(record => record.meta.requiresAuth)) {
//     let name = getCookie("-X_Zka");
//     console.log(name);
//     if(!name){
//   window.location.href =`${window.location.origin}/#/auth`;
// }else{
//   if(to.matched.some(record1 => record1.meta.check_link)) {
//     const jwtDecode = parseJwt(name)
//     // check if user is eligible for the menu
//   var url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT+"/verifyMenu";
//    axios.post(url,{
//     staffid:jwtDecode.staffid,
//     menuPath:to.fullPath
//    })
//                 .then(response => {
//                   if(response.data.code =='00'){
//                     console.log(response.data.message);
//                     if(response.data.message == true){
//                       next()
//                     }else{
//                       window.location.href =`${window.location.origin}/#/auth`;
//                     }
//                   }else{
//                     window.location.href =`${window.location.origin}/#/auth`;
                    
//                   }
//                 })
//                 .catch(e => {
//                   window.location.href =`${window.location.origin}/#/auth`;
//                   alert('Something went wrong')
//                 })  
//   }else{
//     next()
//   }
// }
//   }else{
//     if(to.matched.some(record => record.meta.check_link)) {

//       const jwtDecode = parseJwt(name)
//       // check if user is eligible for the menu
//     var url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT+"/verifyMenu";
//      axios.post(url,{
//       staffid:jwtDecode.staffid,
//       menuPath:to.fullPath
//      })
//                   .then(response => {
//                     if(response.data.code =='00'){
//                       console.log(response.data.message);
//                       if(response.data.message == true){
//                         next()
//                       }else{
//                         window.location.href =`${window.location.origin}/#/auth`;
//                       }
//                     }else{
//                       window.location.href =`${window.location.origin}/#/auth`;
                      
//                     }
//                   })
//                   .catch(e => {
//                     window.location.href =`${window.location.origin}/#/auth`;
//                     alert('Something went wrong')
//                   })    }else{
//     next()
//     }
//   }

})

export default router

// function checkMenu(name){
//   const jwtDecode = parseJwt(name)
//   // check if user is eligible for the menu
//   var url = process.env.VUE_APP_ADMISSIONS_V1_API_ENDPOINT+"/verifyMenu";
//   axios.post(url,{
//   staffid:jwtDecode.staffid,
//   menuPath:to.fullPath
//  })
//               .then(response => {
//                 if(response.data.code =='00'){
//                   console.log(response.data.message);
//                   if(response.data.message == true){
//                     next()
//                   }else{
//                     window.location.href =`${window.location.origin}/#/auth`;
//                   }
//                 }else{
//                   window.location.href =`${window.location.origin}/#/auth`;
                  
//                 }
//               })
//               .catch(e => {
//                 window.location.href =`${window.location.origin}/#/auth`;
//                 alert('Something went wrong')
//               })
// }
