<template>
  <div class="home">
    <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
            <span style="color:#777; font-size:16px;padding:10px;">Course Registration Report </span>
        </div>
    </div>
    <div  class=" container-fluid row m-auto" style="background: #f5f5f5;margin-top:150px !important">
        <div style="margin:auto; padding:20px; " class="col-md-12">
                <v-card elevation="3">
                    <v-card-title style="color:#666; font-size:14px;padding:5px; padding-left:10px">
                        Report search parameters
                    </v-card-title>
                    <v-divider style="margin-top:0px"></v-divider>

                    <v-row cols="12" class="pl-5">
                        <v-col class="col-sm-12 col-md-6 col-lg-2">
<v-text-field dense clearable outlined v-model="regnumber" label="Reg Number"></v-text-field>
</v-col>
<v-col class="col-sm-12 col-md-6 col-lg-2">

<v-autocomplete v-model="session" :items="sessions" style="max-width: 100% !important" outlined label="Select Session" dense></v-autocomplete>
</v-col>
                        <!-- {{departmentSearch}} -->
                        <v-col class="col-sm-12 col-md-6 col-lg-2">

                        <v-autocomplete v-model="depSearch" :items="departmentSearch" style="max-width: 100% !important" outlined label="Select Department" dense></v-autocomplete>
                        </v-col>
                        
<v-col class="col-sm-12 col-md-6 col-lg-2">

                        <v-autocomplete v-model="semester" :items="allSemesters" style="max-width: 100% !important" outlined label="Select Semester" dense></v-autocomplete>
</v-col>                        <v-col class="col-sm-12 col-md-6 col-lg-2">

                        <v-autocomplete v-model="level" :items="levels" style="max-width: 100% !important" outlined label="Select Level" dense></v-autocomplete>
</v-col>
<v-col class="col-sm-12 col-md-6 col-lg-2" style="margin-top:-8px">

                        <v-card-actions>
                        <v-btn color="#c0e7fe" @click="listCourses"  v-if="!activity" class="" >Search</v-btn>
                        <v-btn color="primary"  elevation='2' class="col-md-12 ma-auto"  style="background-color:#e0e0e0 !important;color:#444  !important; " disabled v-else > <v-progress-circular
      indeterminate
      color="#777" :size="25"
    ></v-progress-circular> &nbsp; Searching</v-btn>
                        </v-card-actions>
                        </v-col>
                    </v-row>
                </v-card>


                
                <v-row style="margin:0 !important; padding:0 !important">
        <v-col cols="12" style="margin:40px 0 0 0 !important; padding:0 !important">
          <div class="row">
            <div class="col-md-8 "></div>
            <div class="col-md-4 mb-8">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Table" single-line hide-details></v-text-field>
            </div>
          </div>
          <v-data-table :headers="headers"
      :items="data"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1"
      @page-count="pageCount = $event" :search="search">

      <template v-slot:[`item.sn`]="{ item }">
                        <tr>
                            <td class="table-txt">{{data.map(function(x) {return x.snId; }).indexOf(item.snId)+1}}</td>
                        </tr>
                    </template>

                    <template v-slot:item.registered="{item}">
                      <tr>
                            <td v-if="item.status == '1'" style="color: green;">Registered</td>
                            <td v-if="item.status == '0'" style="color: orangered;">Not Registered</td>
                        </tr>
                      </template>

            <template v-slot:item.actions="{item}">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="" text v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="getInfo(item.el)">
                    <v-list-item-title>
                      <v-icon small class="mr-2">
                        mdi-clipboard-arrow-left
                      </v-icon>Registration Info
                    </v-list-item-title>
                  </v-list-item>

                  <!-- <v-list-item @click="openRegisteredCourses(item.el)">
                    <v-list-item-title>
                      See Registered Courses</v-list-item-title>
                  </v-list-item> -->
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
      ></v-pagination>
      <v-spacer></v-spacer>
     
      </div>

        </v-col>
      </v-row>

            </div> 



    </div>


    
    <v-dialog v-model="dialogRegset" width="400">
    <v-card>
      <v-card-title style="color:#666; font-size:16px; position:fixed">
        Reset course registration
        <v-spacer></v-spacer>
        <v-icon @click="dialogRegset=false">mdi-close-circle</v-icon>

      </v-card-title>
      <v-divider style="margin-top:0px"></v-divider>

      <v-card-text>
        <v-row>

          <div class="col-md-12 pa-8 text-center">
            <!-- <img style="height: 150px; width: 150px" src="../../../assets/caution.gif" alt=""> <br> -->
            <v-icon class="m-auto" color="error" > mdi-48px mdi-sign-caution</v-icon><br>
            Are you sure you want to reset course registration for <strong>{{courseInfo?.studentId?.surname}} {{courseInfo?.studentId?.firstname}} {{courseInfo?.studentId?.othernames}}</strong>. What this means is that this student will have to re-submit the course registration to be seen as to have registered for departmental courses.
          </div>

        </v-row>
        <v-card-actions class="m-auto">
          <v-btn color="#c0e7fe" @click="resetCourse" class="col-md-5 ma-auto" v-if="!activity">Proceed</v-btn>
          <v-btn color="primary"  elevation='2' class="col-md-7 ma-auto"  style="background-color:#e0e0e0 !important;color:#444  !important;padding:10px;cursor: pointer !important; " disabled v-else > <v-progress-circular
      indeterminate
      color="#777" :size="25"
    ></v-progress-circular> &nbsp; Resetting</v-btn>
        </v-card-actions>
      </v-card-text>

    </v-card>
  </v-dialog>





  <v-dialog v-model="dialogCourse" max-width="550" >
    <v-card>
      <v-card-title style="color:#666; font-size:18px">
       Registered Course
        <v-spacer></v-spacer>
        <v-icon @click="dialogCourse=false">mdi-close-circle</v-icon>

      </v-card-title>
      <v-divider style="margin-top:0px"></v-divider>

      <v-card-text>
        <v-row>


          <div class="col-md-12 pa-7 pb-0" v-if="courseInfo">
            <h6>Bio Information</h6>
           <div class=" d-flex justify-start align-center">
            <img style="height: 150px; overflow: hidden; width: 150px; border-radius: 50%; border: 1px solid gray" :src="courseInfo?.studentId?.profileUrl" alt="">
            <div class="ml-7">
                            <p><strong>Reg. No.: </strong>{{courseInfo.studentId.regNumber}}</p>
              <p><strong>Surname: </strong>{{courseInfo.studentId.surname}}</p>
              <p><strong>First Name: </strong>{{courseInfo.studentId.firstname}}</p>
              <p><strong>Othernames: </strong>{{courseInfo.studentId.othernames}}</p>
              <p><strong>Current Level:  </strong>{{courseInfo.studentId.level}}00</p>
              <p><strong>Department: </strong>{{courseInfo.departmentId.name}}</p>
           </div>

            </div>
          </div>

          <div class="col-md-12" v-if="courseInfo">
            <v-divider style="margin-top:0px"></v-divider>
            <p><strong>Registration Session:  </strong>{{session.slug}} </p>
            <p v-if="courseInfo.status=='1'"><strong>Level when registered: </strong>{{courseInfo.level}}00 </p>
            <p><strong>Registration Semester:  </strong> <span v-if="courseInfo.semester == 1">1st Semester</span><span v-else>2nd Semester</span></p>
            <p><strong>Registration Status:  </strong> <span v-if="courseInfo.status==1">Registered</span><span v-if="courseInfo.status==0">Not Registered</span></p>
            <strong v-if="courseInfo.status=='1'">Registration Date:  </strong> <span v-if="courseInfo.status==1">{{ courseInfo.dateRegistered }}</span>

          </div>
          <div class="col-md-12" v-if="courseInfo">
            <h6 v-if="courseInfo.status==1">Registered Courses</h6>
            <h6 v-if="courseInfo.status==0">Courses in portfolio </h6>
            <v-divider style="margin-top:0px"></v-divider>
          <div class="col-md-12">
            <v-list v-if="courseInfo">
      <v-list-item-group>
        <v-list-item
          v-for="(item, i) in courseInfo.regdCourses"
          :key="i"
        >
          <v-list-item-icon>
            <v-icon>mdi-decagram-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.course.name"></v-list-item-title>
            <span>{{item.course.course_code}}--({{ item.unit }} Credit Unit)</span>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-btn color="error"  @click="reset()">Reset Registration</v-btn>
          </div>
          </div>


        </v-row>
 
      </v-card-text>

    </v-card>
  </v-dialog>



    </div>
</template>

<script>
  import { degree, allSession, allDepartments,menusAsigned,fetchactiveSets} from "@Appmart/utility";
  const axiosCall = require("../../helpers/axiosInstance.js")
  import Swal from 'sweetalert2';

export default {
    data() {
  
  return {
    dialogCourse:false,
    page: 1,
        itemsPerPage: 100,
    hideTable:true,
    activity:false,
    levels:[1,2,3,4,5,6],
    level:'',
    session:'',
    depSearch:'',
    search:'',
    semester:'',
    dialogRegset:false,
    departmentSearch:[],
    allSemesters:[{
                            text: 'All',
                            value: '10'
                        },{
                            text: '1st Semester',
                            value: '1'
                        },
                        {
                            text: '2nd Semester',
                            value: '2'
                        }],
    sessions:[{
                            text: '2023/2024',
                            value: {
                                academicYear:'2023',
                                id: '62feaccaa33001cb3a865035',
                                slug: '2023/2024'
                            }
                        },
                        {
                            text: '2022/2023',
                            value: {
                                academicYear:'2022',
                                id: '62feaccaa33001cb3a865035',
                                slug: '2022/2023'
                            }
                        }],
    data: [],
    headers: [{
          text: 'S/N',
          value: 'sn'
        },{
          text: 'Reg Number',
          value: 'reg'
        },
        {
          text: 'Fullname',
          value: 'fullname'
        },
        // {
        //   text: 'Program',
        //   value: 'program'
        // },
        {
          text: 'Department',
          value: 'department'
        },
        {
          text: 'Semester',
          value: 'semester',
        },
        {
          text: 'Level',
          value: 'level'
        },
        // {
        //   text: 'Registered',
        //   value: 'registered'
        // },
        {
          text: 'Date Registered',
          value: 'date',
        },
        {
          text: 'Actions',
          value: 'actions'
        }

      ]
  }
},

computed: {
      pageCount () {
        return Math.ceil(this.data.length / this.itemsPerPage)
      },
    },

mounted() {
    this.mydepartments();
    this.allowedMenu = this.$router.currentRoute.params.getmyMenus;
    this.fetchSession();
      },

methods:{
    async mydepartments() {
        try {

                    const alldepartments = await allDepartments()
          .then((departments) => {
            return departments;
          })
          .catch((e) => {
            console.log(e);
          });

console.log('chiiii',this.allowedMenu);

        ///// fiter the departments that are needed.

        alldepartments.data.message.forEach(dep => {
        this.allowedMenu.department.some((f) => {
          if(f == dep._id){
        this.departmentSearch.push({
                            text: dep.name,
                            value: {
                                id: dep._id,
                                name: dep.name,
                                code: dep.code
                            }
                        });
                      }
                    })
       });



      }catch(e){
console.log(e);
      }

    },
    async fetchSession() {
                    try {
                        const sessions = await allSession()
          .then((activeSet) => {
            return activeSet;
          })
          .catch((e) => {
            console.log(e);
          });
                        if (sessions.data.code === 's200') {
                            sessions.data.message.forEach(session => {
                                this.sessions.push({
                                    value: session.academicYear,
                                    text: session.academicYear,
                                })
                            })
        
                        }
                    } catch (error) {
                        console.log(error);
                    }
                },

    async listCourses(){
      this.activity = true
      this.data=[]
      if (!this.session) {
        this.activity = false
        return Swal.fire({
          icon: 'info',
          text: 'Please select session',
          title: 'Course registration report'
        })
      }
      if (!this.depSearch) {
        this.activity = false
        return Swal.fire({
          icon: 'info',
          text: 'Please select Department',
          title: 'Course registration report'
        })
      }
    //   if (!this.program) {
    //     this.activities = false

    //     return Swal.fire({
    //       icon: 'info',
    //       text: 'Please select program',
    //       title: 'Oops..'
    //     })
    //   }
      try {
        const data = {
          departmentId: this.depSearch ? this.depSearch : 'undefined',
          level: this.level ? this.level : 'undefined',
          session: this.session.academicYear ? this.session : 'undefined',
          semester: this.semester? this.semester : 'undefined',
          regNumber: this.regnumber ? this.regnumber : 'undefined'
        };
        const url =  process.env.VUE_APP_DEPARTMENT_V1_API_ENDPOINT +'/adminfetchRegisteredCourse'

        const res = await axiosCall.axiosPost(url, {data});
        console.log(res);
        if (res.data.code == 's200') {
          const data = []
          if(res.data.message.length <= 0){
            this.activity = false
            return Swal.fire({
          icon: 'info',
          text: 'No record found for the filters selected',
          title: 'Oops..'
        })
          }
          res.data.message.forEach(el => {
            data.push({
              snId:el._id,
              reg: el.level=='1'?el.studentId.admissionNumber:el.studentId.regNumber,
              date:el.dateRegistered,
              fullname: `${el.studentId.surname} ${el.studentId.firstname}`,
              courses : el.recgdCourses,
              // program: el.program.program,
              // faculty: el.facultyId.name,
              department: el.departmentId.name,
              // session: el.session,
              level: el.level + '00',
              semester: el.semester,
              id: el.studentId._id,
              el,
              data: JSON.stringify(el)
            })
          });

          this.data = data;
          this.show = true;
          this.activity = false

        }
      } catch (error) {
        console.log(error);
      }
    
    },


    async resetCourse(){
        this.activity = true
    try {
      const url =  process.env.VUE_APP_DEPARTMENT_V1_API_ENDPOINT +'/adminresetcourse'
      const data = {studentId: this.courseInfo.studentId._id, program: this.program, session: this.session, semester:this.courseInfo.semester}
     const res = await axiosCall.axiosPost(url, {data});

     if(res.data.code == 's200'){
       this.dialogRegset = false;
       this.dialogCourse = false
       this.data = this.data.filter(el => el.id !== this.courseInfo.studentId._id);
    //   if(res.data.notremoved.length > 0){
    //       const element = res.data.notremoved[index];
    
    //   this.data = this.data.map(function(el) {
    //     if(el.snId == this.courseInfo.snId){
    //     const fafter = el.courses.filter(vc => vc._id !== el.element);
          
    //     }
    //   })
    
    // }
       let text
if(res.data.notremoved.length == 0){
text = `You have successfully reset course registration ${this.courseInfo.studentId.surname} ${this.courseInfo.studentId.firstname}. All courses were succesfully removed from the result sheet`
Swal.fire({icon: 'success', text: text})
}else{
  text  = `You have successfully reset course registration ${this.courseInfo.studentId.surname} ${this.courseInfo.studentId.firstname}. Not all courses were succesfully removed from the result sheet`
  Swal.fire({icon: 'warning', text: text})

}
      
       this.activity = false
     }else{
        this.activity = false
      Swal.fire({icon: 'error', text: res.data.message, title: 'Oops...'})
     }
    } catch (error) {
        this.activity = false
      console.log(error)
    }
    },

    getInfo(item) {
      this.courseInfo = item;
      // this.dialogRegset = true;
      this.dialogCourse = true
    },
    reset() {
      this.dialogRegset = true;
    },
}

}
</script>

<style>

</style>