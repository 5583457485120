<template>
     <div class="home">
    <div class="row">
        <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
            <span style="color:#777; font-size:16px;padding:10px;">Department / approve profiled course </span>
        </div>
    </div>
    <!-- <div v-if="options" class="profileCourse"> -->
        <div style="background: #f5f5f5; margin-top:150px; " class=" container-fluid row ">
            <div style="margin:auto; padding:20px; margin-top:100px" class="col-md-5" v-if="showForm">
                <v-card elevation="3" height="auto">
                    <!-- <v-card-title style="color:#666; font-size:18px">
                        Select Department
                    </v-card-title> -->
                    <v-divider style="margin-top:0px"></v-divider>

                    <v-col cols="11" style="margin:auto;padding-top:45px" pa-4>
                        <!-- {{departmentSearch}} -->
                        <v-autocomplete v-model="department" :items="departments" style="max-width: 100% !important" outlined label="Select Department"></v-autocomplete>
                        <!-- <v-autocomplete v-model="degree" :items="degrees" style="max-width: 100% !important" outlined label="Select Degree"></v-autocomplete> -->
                        <v-autocomplete v-model="level" :items="levels" style="max-width: 100% !important" outlined label="Select Level"></v-autocomplete>
                        <v-autocomplete v-model="semester" :items="semesters" style="max-width: 100% !important" outlined label="Select Semester"></v-autocomplete>

                        <!-- <v-text-field outlined label="Enter staff CIRMS ID / staff ID" v-model="staffId" placeholder="CIRMS ID / Staff ID"></v-text-field> -->
                        <v-card-actions>
                        <v-btn color="#c0e7fe" @click="fetchProfiledCourse" :disabled="staffId==''" v-if="!activity" class="col-md-7 ma-auto" >Next</v-btn>
                        <v-btn color="primary"  elevation='2' class="col-md-7 ma-auto"  style="background-color:#e0e0e0 !important;color:#444  !important;padding:10px;cursor: pointer !important; " disabled v-else> <v-progress-circular
      indeterminate
      color="#777" :size="25"
    ></v-progress-circular> &nbsp;Processing</v-btn>
                        </v-card-actions>
                    </v-col>
                </v-card>
            </div>
            <div class="col-md-12" v-else style="padding:20px; margin-top:30px">
                <v-row style="margin:0 !important; padding:0 !important">
    <v-col cols="12" style="">
        <div class="row">
            <div class="col-md-8 "> <span
          class="mb-5 d-block"
          @click="back"
          style="cursor: pointer"
        >
          <v-icon>mdi-arrow-left-circle</v-icon>
          Back
        </span></div>
            <div class="col-md-4 mb-8">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search profiled course" single-line hide-details></v-text-field>
            </div>
        </div>
                
               
                <v-card elevation="1">
                    <v-card-title class="cardTableHeader">
                        Approve <strong>&nbsp;{{this.department.name}} {{this.level.name}} level {{this.semester.name}}&nbsp;</strong> semester profiled
                        courses
                        </v-card-title>
                        <v-divider style="margin:0"></v-divider>
                    <v-card-title style="position: relative">
                       
                        <v-spacer></v-spacer>
                        <v-btn :disabled="selected.length == 0" color="danger" @click="showApprovalDialog('reject')" class="mr-3">Reject</v-btn>
          <v-btn :disabled="selected.length == 0" color="success" @click="showApprovalDialog('approve')">Approve</v-btn>
                        <!-- <v-btn color="success" @click="approveProfileCourse('approve')" style="margin-right:10px">Approve</v-btn>
                        <v-btn color="error" @click="approveProfileCourse('reject')" >Reject</v-btn> -->
                    </v-card-title>
                    <v-flex  style="overflow: auto"> 
                    <v-data-table v-model="selected" :single-select="false" :headers="headers" :items="desserts"
                        item-key="key" show-select :search="search" class="elevation-1">
                    </v-data-table>
                    </v-flex>
                </v-card>
                </v-col>
                </v-row>
            </div>
            
        </div>

        <v-dialog v-model="showApproval" width="450">
      <v-card style="overflow: hidden" class="text-center pa-8">
        <h5 class="mb-4">{{info}}</h5>
        <v-row>
          <v-col cols="6">
            <v-btn block @click="showApproval=false">cancel</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn block @click="approveProfileCourse">procceed</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
export default {

    data(){
        return {
            showForm: true,
            search: '',
            selected: [],
            showApproval:false,
            info:'',
            reject:false,
            headers: [
                {
                    text: 'Course Name',
                    value: 'coursename'
                },
                {
                    text: 'Program',
                    value: 'program'
                },
                {
                    text: 'Department',
                    value: 'dep'
                },
                {
                    text: 'Code',
                    value: 'code'
                },
                {
                    text: 'Gs',
                    value: 'gs'
                },
                {
                    text: 'Level',
                    value: 'level'
                },
                {
                    text: 'Semester',
                    value: 'semester'
                },
                {
                    text: 'Unit',
                    value: 'unit'
                },
                {
                    text: 'Compulsory',
                    value: 'comp'
                },
                {
                    text: 'Created By',
                    value: 'createdby'
                },
                {
                    text: 'Date Profiled',
                    value: 'date'
                },
                
            ],
            levels: [
       
        {
          value: {
            name: "100",
            val: 1,
          },
          text: "100",
        },
        {
          value: {
            name: "200",
            val: 2,
          },
          text: "200",
        },
        {
          value: {
            name: "300",
            val: 3,
          },
          text: "300",
        },
        {
          value: {
            name: "400",
            val: 4,
          },
          text: "400",
        },
        {
          value: {
            name: "500",
            val: 5,
          },
          text: "500",
        },
        {
          value: {
            name: "600",
            val: 6,
          },
          text: "600",
        },
      ],
      activity:false,
            desserts: [],
            programs: [],
            sessions: [],
            program: '',
            session: '',
            degrees: [],
            semesters: [
        {
          value: {
            name: "First",
            val: 1,
          },
          text: "First",
        },
        {
          value: {
            name: "Second",
            val: 2,
          },
          text: "Second",
        },
      ],
      departments: [],

            departmentsArr:[],
            ///// holds the menus,departments and also the route that is allowed for this user
allowedMenu:'',
        }
    },
    mounted(){
    this.program = this.$router.currentRoute.params.activeSetting.data.data.program._id;
    /****
     * this brough the allowed menus within this scope
     * so filter through to gee the actual departments to represent
     * 
     *  */ 


    this.allowedMenu = this.$router.currentRoute.params.getmyMenus;
     /// filter the departments this user is supposed to operate as and use as department array
       this.$router.currentRoute.params.activeDepartments.data.message.forEach(dep => {
        this.allowedMenu.department.some((f) => {
          if(f == dep._id){
        this.departmentsArr.push({
                            text: dep.name,
                            value: {
                                id: dep._id,
                                name: dep.name,
                                code: dep.code
                            }
                        });
                      }
                    })
       });

console.log('lobatab',this.allowedMenu);

       
      //   const nnn = this.departmentsArr.filter((el) => {
      //     return this.allowedMenu.department.some((f) => {
      //       return f == el.value.id ;
      //     });
      //   });

      //  console.log('after filter',nnn);
       
       ////// remember to pass the menu to this function
       this.checkAdminType('menu');

  },
    beforeMount(){
       // this.fetchSession();
        // this.fetchProgram();
    },

    watch: {
    department(val) {
      this.getDegrees(val);
    },
  },

    methods: {
      showApprovalDialog(action) {
        this.reject=false;
      if (action == 'approve') {
        this.info = `Are you sure you want to ${action} this courses?`;
      } else if (action == 'reject') {
        this.reject = true;
        this.info = `Are you sure you want to ${action} this courses?`;
      }
      this.showApproval = true;
    },
        async getDegrees(dep) {
      if (!this.program)
        return Swal.fire({
          icon: "error",
          title: "Oops..",
          text: "Please make sure you select program",
        });
      try {
        const url = process.env.VUE_APP_DEPARTMENT_V1_API_ENDPOINT+'/department-degrees'
        const res = await axios.post(url,{ departmentId: dep.id, programId: this.program }
        );
        console.log(res);
        if (res.data.code === "s200") {
          this.degrees = res.data.message.resData;
        }else{
          this.degrees = [];
        }
      } catch (error) {
        console.log(error);
      }
    },
        async checkAdminType(menu) {
      let staffLevel = "100";
      //Fetch departments
    //   await this.fetchDepartments();

      //fetch programs
    //   await this.fetchProgram();

      //check admin type
      if (staffLevel == "100") {
        this.departments.push(...this.departmentsArr)
        console.log(this.departments);
        return
      }

      //Get route path
      const path = this.$route.path;
      //find the submenu in menu

      let submenuData = null;
      //Filter out current submenu
      menu.forEach((adminmenu) => {
        adminmenu.sub_menu.forEach((submenu) => {
          if (submenu.submenu_link === path) {
            submenuData = submenu;
            return;
          }
        });
      });

      // console.log('SUBBBBBBBB', path, submenuData);

      //Compare programs
      for (let i = 0; i < this.programsArr.length; i++) {
        for (let j = 0; j < submenuData.programs.length; j++) {
          if (this.programsArr[i].value.id == submenuData.programs[j]) {
            this.programs.push(this.programsArr[i]);
          }
        }
      }

      // console.log('DEPARTMENT ARR',this.departmentsArr);
      // Compare push departments
      for (let i = 0; i < this.departmentsArr.length; i++) {
        for (let j = 0; j < submenuData.departments.length; j++) {
          // console.log('REACHED', this.programsArr[i]);
          if (this.departmentsArr[i].value.val == submenuData.departments[j]) {
            this.departments.push(this.departmentsArr[i]);
          }
        }
      }
    },
        async fetchSession() {
            try {
const url = process.env.VUE_APP_DEPARTMENT_V1_API_ENDPOINT+'/fetch-session'
                const res = await axios.get(url);
                if (res.data.code === 's200') {
                    res.data.message.forEach(session => {
                        this.sessions.push({
                            value: session.academicYear,
                            text: session.academicYear,
                        })
                    })

                }
            } catch (error) {
                console.log(error);
            }
        },
        back(){
            this.department = '';
            this.level = this.levels;
            this.semester = this.semesters;
            this.showForm  = true;
        } ,      
        //  async fetchProgram() {
        //     try {
        //         const url = /fetch-session
        //         const res = await axios.get('http://localhost:5000/fetchprograms');
        //         console.log('RESET', res)
        //         if (res.data.code == '00') {
        //             res.data.data.forEach(program => {
        //                 this.programs.push(program.program)
        //             });
        //         } else {
        //             console.log("ERROR")
        //         }
        //     } catch (error) {
        //         console.log(error)
        //     }
        // },

        async approveProfileCourse(){
            try {
                const url = process.env.VUE_APP_DEPARTMENT_V1_API_ENDPOINT+'/approveReject'
                const courses = this.selected.map(el => el.id)
               const data= { courseIds: courses, program: this.program, session: this.session, appRefby:this.$logedIndataemail}
                  if(this.reject){
                  data['reject'] = true
                }
                const res = await axios.post(url, data);
                
                               if(res.data.code == 's200'){
                    // this.fetchProfiledCourse();
                    this.selected.forEach(el => {
                        this.desserts.splice(this.desserts.indexOf(el), 1)
                    })
this.showApproval = false
this.reject = false;
this.courses=[];
                    Swal.fire({
                        text: res.data.message,
                        title:'Successful',
                        icon: 'success'
                    })
                 }else{
                    Swal.fire({
                        text: res.data.message,
                        title: 'Oops..',
                        icon: 'error'
                    })
                 }
            } catch (error) {
                console.log(error);
            }
        },  
       async fetchProfiledCourse(){
        this.activity = true
            try {
               const url = process.env.VUE_APP_DEPARTMENT_V1_API_ENDPOINT+'/fetch-profiled-for-approval'
                this.desserts = []
                const res = await axios.post(url, 
                {department:this.department.id , semester: this.semester.val, program:this.program, level:this.level.val})
                console.log(res);
                if(res.data.code == 's200'){
                    if(res.data.message.length <=0){
                            Swal.fire({
                        text: `There is no course awaiting approval for ${this.department.name} ${this.semester.name} semester ${this.level.name} level`,
                        title: 'Profiled Course Approval',
                        icon: 'error'
                    })
                    this.activity = false
                    return
                        }
                    res.data.message.forEach(el => {
                        
                        this.desserts.push({
                            coursename: el.course.name,
program: el.program.program,
dep: el.department.name,
code: el.code,
gs: el.isGsCourse ? 'Yes' : 'No',
level: el.level ,
semester:el.semester ,
unit: el.unit,
comp: el.compulsory ? 'Yes' : 'No',
id: el._id,
key: `${el._id}${el.department.name}${Date.now()}`,
createdby: el.createdBy,
date: el.dateCreated.split('T')[0] ,
                        })
                    })
                    this.showForm = false;
                    // this.program = '';
                    // this.session = '';
                }
                this.activity = false
            } catch (error) {
                console.log(error);
                this.activity = false
            }
        }
    }

}
</script>

<style>

</style>