<template>
    <div class="home">
   <div class="row">
       <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
           <span style="color:#777; font-size:16px;padding:10px;">Department / approve Profile Edit </span>
       </div>
   </div>
   <div style="background: #f5f5f5; margin-top:180px" class=" container-fluid">
        <!-- <div class="row" style="margin-bottom:20px">
            <div class="col-md-8" style="font-size:18px; padding-left: 20px;">All Department List </div>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search Departments" single-line hide-details></v-text-field>

        </div> -->
        
        <v-row style="margin:0 !important; padding:0 !important">
    <v-col cols="12" style="">
        <v-data-table v-model="selected" :single-select="false" :headers="headers" :items="desserts"
                       item-key="key"  :search="search" class="elevation-1" :items-per-page="100">
                       
                       <template v-slot:item.type="{ item }">
                        <div v-if="item.type == 1" small >
                                        Delete
                        </div>
                        <div v-if="item.type == 2" small >
                                        Update
                        </div>
                                </template>
    

                       <template v-slot:item.actions="{ item }">
                                   <v-btn x-small @click="showEdit(item)" v-if="item.type == 1" color="error">
Approve
                                   </v-btn>
                                   <v-btn x-small @click="showEdit(item)" v-if="item.type == 2" color="info">
Approve
                                   </v-btn>
                                </template>
                   </v-data-table>           
           
            </v-col></v-row>       
       </div>
       <v-dialog v-model="showApproval" :width="widthmodal">
     <v-card style="overflow: hidden" class="text-center">
        <v-card-title>
       <h5 class="" v-if="item.type==1"> Approve to delete {{item.code}}</h5>
       <h5 class="" v-if="item.type==2"> Approve to Edit {{item.code}}</h5>
    </v-card-title>
    <v-divider style=""></v-divider>
    <v-card-text>
        <v-text-field v-model="item.code" dense outlined label="Course" readonly></v-text-field>
        <v-alert style="background-color: #ccc;" v-if="unitedit">
        <v-text-field v-model="oldrecitem[0].unit" dense outlined label="Old Credit load value " readonly v-if="item.type==2"></v-text-field>
        <v-text-field v-model="item.unit" dense outlined label="Updated Credit Load value" readonly></v-text-field>
        </v-alert>
        <v-text-field v-model="item.unit" dense outlined label="Updated Credit Load value" readonly v-else></v-text-field>
        <v-alert style="background-color: #ccc;" v-if="gsedit">
        <v-text-field v-model="oldrecitem[0].isGsCourse" dense outlined label="Old is course GS value " readonly v-if="item.type==2"></v-text-field>
        <v-text-field v-model="item.isGsCourse" dense outlined label="New is course GS value" readonly></v-text-field>
        </v-alert>
        <v-text-field v-model="item.isGsCourse" dense outlined label="New is course GS value" readonly v-else></v-text-field>

        <v-text-field v-model="deptName" dense outlined label="Requesting Department" readonly></v-text-field>
        <v-text-field v-model="item.level" dense outlined label="Level" readonly></v-text-field>
        <v-text-field v-model="item.semester" dense outlined label="Semester" readonly></v-text-field>

    </v-card-text>
    <v-card-action >
       <v-row class="pa-5">
         <v-col cols="6">
           <v-btn block @click="showApproval=false" small>cancel</v-btn>
         </v-col>
         <v-col cols="6">
           <v-btn   small v-if="item.type==2" color="info" @click="approveProfileCourse">Approve update</v-btn>
           <v-btn  small v-if="item.type==1" color="error" @click="approveProfileCourse">Approve Delete</v-btn>

         </v-col>
       </v-row>
    </v-card-action>
     </v-card>
   </v-dialog>
   </div>
   </div>
   
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
export default {

   data(){
       return {
        unitedit:false,
        gseditor:false,
        item:'',
        oldrecitem:'',
        oldrecord: '',
        widthmodal:'',
        deptName:'',
           showForm: true,
           search: '',
           selected: [],
           showApproval:false,
           info:'',
           reject:false,
           headers: [
               {
                   text: 'Course',
                   value: 'code'
               },
               {
                   text: 'Unit',
                   value: 'unit'
               },
               {
                   text: 'Gs',
                   value: 'isGsCourse'
               },
               {
                   text: 'Department',
                   value: 'department.name'
               },
               {
                   text: 'Level',
                   value: 'level'
               },
               {
                   text: 'Semester',
                   value: 'semester'
               },
               {
                   text: 'type',
                   value: 'type'
               },
               ,
               {
                   text: 'Request by',
                   value: 'createdBy'
               },
               {
                   text: 'Date Requested',
                   value: 'dateRequested'
               },
               {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
           ],
           desserts:[],
          
            }
   },
   mounted(){
    this.fetchalltoEdit();
 },
 

   methods: {

 async fetchalltoEdit(){

try{
    const url = process.env.VUE_APP_DEPARTMENT_V1_API_ENDPOINT+'/fetchCourseEdit'
    const res = await axios.get(url,{ })
    if (res.data.code === "00") {
         this.desserts = res.data.message.alltoedit;
         this.oldrecord = res.data.message.original
       }
}catch(e){

}

 },

 showEdit(item){
    this.unitedit = false
    this.gsedit= false
this.item = item
this.showApproval = true
this.deptName = item.department.name
if(item.type==1){
    this.widthmodal = '550'
}else{
     this.oldrecitem = this.oldrecord.filter(x=>{
        if(item.type==2 && x.course==item.course){
            return x
        }
    })
    this.unitedit= this.oldrecitem[0].unit !=item.unit?true:false
    this.gsedit= this.oldrecitem[0].isGsCourse !=item.isGsCourse?true:false

    this.widthmodal = '550'
}
 },

 async approveProfileCourse(){
    try{
    const url = process.env.VUE_APP_DEPARTMENT_V1_API_ENDPOINT+'/approveProfileEdit'
    const res = await axios.post(url,{item:this.item,appRefby:this.$logedIndataemail})
    if (res.data.code == "00") {
        const courseIndex = this.desserts.indexOf(
            this.desserts.find((el) => this.item._id === el._id)
          );
          this.desserts.splice(courseIndex, 1);
        this.showApproval = false
        return Swal.fire({
         icon: "success",
         title: "Approval",
         text: "Approval was successful",
       });
       }
}catch(e){

}
 },
     
   }

}
</script>

<style>

</style>